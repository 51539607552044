
/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {

}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
}


/*social followup star */
.social-container {
  position: relative;
    background: white;
    padding: 0px 0px;
    z-index: 10;
    margin-left: 10vw;
  }
  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }
  a.social:hover {
    transform: translateY(-2px);
  }
  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }
  /*social followup end */
