body, html {
  height: 100%;
}
body > #root > div {
 max-width: 100%;
 max-height: 100%;
 height: 100%;
 width: 100%;
}
body{
  height: 100%;
  width: 100%;
}
html, body, #root, .Login {
  height: 100%;
}
.LoginMenuLinksText{
  text-transform: uppercase;
  font: 700 22px/1 "Arvo", sans-serif;
  text-align: center;
}
.LoginMenutextbrand{

  transform: translate(10%,-10%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 0.8em;
  font-weight: 100;
  color :#a600ff;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  transform-style: preserve-3d;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
  -webkit-animation: shine01 4s linear infinite;
          animation: shine01 4s linear infinite;
 
  
}

#offset {
  animation: letter-flicker 2s linear infinite;
}
@keyframes letter-flicker {
  0% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  2% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  4% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  
  19% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  21% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  23% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  80% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  83% {
    opacity:0.4;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  87% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
}



/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {
    .bkimage {
        background-position: relative center;
        width: 100vh;
        height: 100vh;
        padding-left: 2.9%;  
        padding-bottom: 2.9%;  
        background-size: cover;       
        transform: rotateZ(360deg);
        object-fit: cover;

    }
    .img-responsive{
        display: block;
        max-width: 100%;
        height: auto;
        }
        .login-hide-show{
          text-align: center;
          position: absolute;
          top: 20%;
          left: 60%;
          transform: translate(-50%, -50%);
          -webkit-transform:translate(-50%, -50%);
          color: white;
       
          text-transform: uppercase;
          font-family: verdana;
          font-size: 3em;
          font-weight: 1000;
          size: 500px;
          z-index: 99;
      
          transform:  perspective(1000px)  rotateZ(10deg) ;
          -webkit-transform: perspective(1000px)  rotateZ(10deg) ;
        }
        .hero-text {
          text-align: center;
          position: absolute;
          top: 10%;
          left: 20%;
          font-size: 0.5em;
          transform: translate(-50%, -50%);
          color: white;
          z-index: 199;
          transform:  perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
          -webkit-transform:perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
        }

        #hero-1:before {
          background-image: url("../images/test14.jpeg");
          transform: translateZ(-1px) scale(2);
          -webkit-transform:translateZ(-1px) scale(2);
          z-index: 25;
        }
        
        #hero-2:before {
          background-image: url("../images/test11.jpeg");
          transform: translateZ(-1px) scale(2);
          -webkit-transform:translateZ(-1px) scale(2);
          z-index: 25;
        }
        
        #hero-3:before {
          background-image: url("../images/test1.jpeg");
          transform: translateZ(-1px) scale(2);
          -webkit-transform:translateZ(-1px) scale(2);
          z-index: 25;
        }
        
        #hero-4:before {
          background-image: url("../images/test9.jpeg");
          transform: translateZ(-1px) scale(2);
          -webkit-transform:translateZ(-1px) scale(2);
          z-index: 25;
        }
      
        .hero-imageposter {
          height: 70vh;
          width: 100vw;
         }
         #hero-8{
          /* margin-left: 5vw; */
        }
}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
    .bkimage {
      width: 100vw;
      height: 100vh;
        background-position: center center;
        transform: rotateZ(360deg);
        object-fit: cover;
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }
    .login-hide-show{
      text-align: center;
      position: absolute;
      top: 20%;
      left: 60%;
      transform: translate(-50%, -50%);
      -webkit-transform:translate(-50%, -50%);
      color: white;
   
      text-transform: uppercase;
      font-family: verdana;
      font-size: 3em;
      font-weight: 1000;
      size: 500px;
      z-index: 99;
  
      transform:  perspective(1000px) rotateY(1deg) rotateZ(10deg) rotateX(1deg);
      -webkit-transform:perspective(1000px) rotateY(1deg) rotateZ(10deg) rotateX(1deg);
    }
    .hero-text {
      text-align: center;
      position: absolute;
      top: 10%;
      left: 20%;
      font-size: 1em;
      transform: translate(-50%, -50%);
      -webkit-transform:translate(-50%, -50%);
      color: white;
      z-index: 199;
      transform:  perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
      -webkit-transform:perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
    }

    #hero-1:before {
      background-image: url("../images/test14.jpeg");
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: 25;
    }
    
    #hero-2:before {
      background-image: url("../images/test11.jpeg");
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: 25;
    }
    
    #hero-3:before {
      background-image: url("../images/test1.jpeg");
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: 25;
    }
    
    #hero-4:before {
      background-image: url("../images/test9.jpeg");
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: 25;
    }
    
    .normal-scroll {
      background: #fff;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
    }

    .hero-imageposter {
      height: 200vh;
    
     }
    
     #hero-8{
      /* margin-left: 10vw; */
    }
}

/* For desktop devices */
@media (min-width: 1025px) {
  /*  .bkimage {
      background-position: center center;
      transform: rotateZ(360deg);
      object-fit: cover;
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }*/
    .bkimage {
    width: 100vw;
    height: 100vh;
      background-position: center center;
      transform: rotateZ(360deg);
      object-fit: cover;
      
    }

    .login-hide-show{
      text-align: center;
      position: absolute ;
      top: 40%;
      left: 40%;
      transform: translate(-50%, -50%);
      color: white;
   
      text-transform: uppercase;
      font-family: verdana;
      font-size: 3em;
      font-weight: 1000;
      size: 500px;
      z-index: 99;
  
      transform:  perspective(1000px) rotateY(1deg) rotateZ(10deg) rotateX(1deg);
      -webkit-transform:perspective(1000px) rotateY(1deg) rotateZ(10deg) rotateX(1deg);
    }
    .hero-text {
      text-align: center;
      position: absolute;
      top: 10%;
      left: 20%;
      font-size: 2em;
      transform: translate(-50%, -50%);
      color: white;
      z-index: 199;
      transform:  perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
      -webkit-transform: perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
    }


    #hero-1:before {
      background-image: url("../images/test14.jpeg");
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: -1;
    }
    
    #hero-2:before {
      background-image: url("../images/test11.jpeg");
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: -2;
    }
    
    #hero-3:before {
      background-image: url("../images/test1.jpeg");
      background-attachment: fixed;
      z-index: -3;
    }
    
    #hero-4:before {
      background-image: url("../images/test9.jpeg");
      background-attachment: fixed;
      z-index: -4;
    }

    .normal-scroll {
      background: #fff;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
    }
    
    .parallexcontainer {
      
      color: #ccc;
      font-size: 4vw;
      margin: 0 auto;
      padding: 25vh 0;
      text-align: center;
      width: 100%;
      

    }

    .hero-imageposter {
      height: 200vh;
    
     }
     #hero-8{
      /* margin-left: 20vw; */
    }
}
@keyframes spinHorizontal {
    0% { transform: rotateZ(0deg); }
    100% { transform: rotateZ(360deg); }
    
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

  .bkimage {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/test16.jpeg');
    background-origin: border-box;
    border-left: 5px solid rgba(0, 0, 0, 0.5);

    /*-moz-animation: spinHorizontal 20s 5 linear;
    -o-animation: spinHorizontal 20s 5 linear;    
    -webkit-animation: spinHorizontal 20s 5 linear;
    animation: spinHorizontal 20s 5 linear;*/

    
    -webkit-transform: perspective(1000px) rotateZ(360deg);
    transform: perspective(1000px) rotateZ(360deg);
 
  
   
    background-origin: border-box;
    border-left: 5px solid rgba(0, 0, 0, 0.5);

  
    
  }

  
  .logincontainer {
  /*display: grid;*/
  width: 100vw;
  height: 100vh;
  max-width: 100%;
 /* grid-template-columns: 1fr;*/

}

.div-block-87 img {
  max-width: 100%;
  height: auto;
  
}
.loginimageclass{
  position: absolute;
  height: 80%;
  width: 80%;
  -webkit-transform:  rotateZ(360deg);
  transform:  rotateZ(360deg);
  object-fit: contain;
  margin-left: 5rem;
  -webkit-transform-origin:50% 50%;
  -moz-transform-origin:50% 50%;
  -ms-transform-origin:50% 50%;
  -o-transform-origin:50% 50%;
  transform-origin:50% 50%;
 /* -webkit-animation:scaleLoginImage 30s  linear 0s infinite;
  animation:scaleLoginImage 30s linear 0s infinite;
  
  transition:all 1s ease-in-out;
  -webkit-transition:all 1s ease-in-out;
  -moz-transition:all 1s ease-in-out;
  -ms-transition:all 1s ease-in-out;
  -o-transition:all 1s ease-in-out;
  transition:all 1s ease-in-out;
*/
}

@-ms-keyframes loginkenburns
{
  0%
  {
    -o-transform:scale(1);
    -o-transition:-o-transform 35000ms linear 0s
  }
  100%
  {
    -o-transform:scale(1.3);
    -o-transition:-o-transform 35000ms linear 0s
  }
}
@-o-keyframes loginkenburns
{
  0%
  {
    -o-transform:scale(1);
    -o-transition:-o-transform 35000ms linear 0s
  }
  100%
  {
    -o-transform:scale(1.3);
    -o-transition:-o-transform 35000ms linear 0s
  }

}
@keyframes loginkenburns
{
  0%
  {
    transform:scale(1);
    transition:transform 35000ms linear 0s
  }
  100%
  {
    transform:scale(1.3);
    transition:transform 35000ms linear 0s
  }

}

@keyframes scaleLoginImage {
      From {
        -webkit-transform: scale(0.2);
        transform:scale(0.2);
        -webkit-transition:transform 1s linear 0s;
        transition:transform 1s linear 0s;
    }
    To {
        -webkit-transform: scale(2.0);
        transform:scale(2.0);
        -webkit-transition:transform 1s linear 0s;
        transition:transform 1s linear 0s;
    }
}

/*firework start */

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 30%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  z-index: 100;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -202px -87.6666666667px #00ff4d, 248px 65.3333333333px #0051ff, 216px -344.6666666667px #ff0059, -85px 29.3333333333px #1e00ff, -178px -315.6666666667px #3700ff, -116px -25.6666666667px #66ff00, 149px -112.6666666667px #09ff00, -170px -240.6666666667px #00fff2, -67px -349.6666666667px #f700ff, -115px -30.6666666667px #3700ff, -162px -334.6666666667px #00ff15, -31px 75.3333333333px #a600ff, -120px -279.6666666667px #59ff00, 249px -223.6666666667px #ff5100, -5px -227.6666666667px #ff00bf, -183px -71.6666666667px #ff009d, 189px -114.6666666667px #00ffbb, -57px -341.6666666667px #e600ff, 3px -88.6666666667px #ff00d0, 192px -352.6666666667px #59ff00, 34px -360.6666666667px #ff002b, 105px -12.6666666667px #d0ff00, -73px -37.6666666667px #bfff00, 28px -243.6666666667px #1a00ff, 15px -256.6666666667px #ff0080, -55px -97.6666666667px #00ff99, 25px -406.6666666667px #dd00ff, -196px 79.3333333333px #00ff5e, -28px -312.6666666667px #2b00ff, -146px 70.3333333333px #00ff99, 110px -234.6666666667px #ff3300, 161px 40.3333333333px #00ff55, 14px -0.6666666667px #ff002f, -211px -53.6666666667px red, 170px 6.3333333333px #0900ff, 176px -201.6666666667px #2600ff, 84px -331.6666666667px #0022ff, 68px -154.6666666667px #008cff, -133px 62.3333333333px #00ff1e, 2px 43.3333333333px #00f2ff, 29px 65.3333333333px #0062ff, -229px 9.3333333333px #ff6600, -202px -390.6666666667px #00ffd9, 237px -264.6666666667px #a2ff00, 101px -78.6666666667px #88ff00, 243px -355.6666666667px #0077ff, 166px 50.3333333333px #00ffaa, 185px -354.6666666667px #00ffdd, -236px -116.6666666667px #ff005e, -12px -77.6666666667px #ff4000, 243px -148.6666666667px #ffa600;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: -202px -87.6666666667px #00ff4d, 248px 65.3333333333px #0051ff, 216px -344.6666666667px #ff0059, -85px 29.3333333333px #1e00ff, -178px -315.6666666667px #3700ff, -116px -25.6666666667px #66ff00, 149px -112.6666666667px #09ff00, -170px -240.6666666667px #00fff2, -67px -349.6666666667px #f700ff, -115px -30.6666666667px #3700ff, -162px -334.6666666667px #00ff15, -31px 75.3333333333px #a600ff, -120px -279.6666666667px #59ff00, 249px -223.6666666667px #ff5100, -5px -227.6666666667px #ff00bf, -183px -71.6666666667px #ff009d, 189px -114.6666666667px #00ffbb, -57px -341.6666666667px #e600ff, 3px -88.6666666667px #ff00d0, 192px -352.6666666667px #59ff00, 34px -360.6666666667px #ff002b, 105px -12.6666666667px #d0ff00, -73px -37.6666666667px #bfff00, 28px -243.6666666667px #1a00ff, 15px -256.6666666667px #ff0080, -55px -97.6666666667px #00ff99, 25px -406.6666666667px #dd00ff, -196px 79.3333333333px #00ff5e, -28px -312.6666666667px #2b00ff, -146px 70.3333333333px #00ff99, 110px -234.6666666667px #ff3300, 161px 40.3333333333px #00ff55, 14px -0.6666666667px #ff002f, -211px -53.6666666667px red, 170px 6.3333333333px #0900ff, 176px -201.6666666667px #2600ff, 84px -331.6666666667px #0022ff, 68px -154.6666666667px #008cff, -133px 62.3333333333px #00ff1e, 2px 43.3333333333px #00f2ff, 29px 65.3333333333px #0062ff, -229px 9.3333333333px #ff6600, -202px -390.6666666667px #00ffd9, 237px -264.6666666667px #a2ff00, 101px -78.6666666667px #88ff00, 243px -355.6666666667px #0077ff, 166px 50.3333333333px #00ffaa, 185px -354.6666666667px #00ffdd, -236px -116.6666666667px #ff005e, -12px -77.6666666667px #ff4000, 243px -148.6666666667px #ffa600;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: -202px -87.6666666667px #00ff4d, 248px 65.3333333333px #0051ff, 216px -344.6666666667px #ff0059, -85px 29.3333333333px #1e00ff, -178px -315.6666666667px #3700ff, -116px -25.6666666667px #66ff00, 149px -112.6666666667px #09ff00, -170px -240.6666666667px #00fff2, -67px -349.6666666667px #f700ff, -115px -30.6666666667px #3700ff, -162px -334.6666666667px #00ff15, -31px 75.3333333333px #a600ff, -120px -279.6666666667px #59ff00, 249px -223.6666666667px #ff5100, -5px -227.6666666667px #ff00bf, -183px -71.6666666667px #ff009d, 189px -114.6666666667px #00ffbb, -57px -341.6666666667px #e600ff, 3px -88.6666666667px #ff00d0, 192px -352.6666666667px #59ff00, 34px -360.6666666667px #ff002b, 105px -12.6666666667px #d0ff00, -73px -37.6666666667px #bfff00, 28px -243.6666666667px #1a00ff, 15px -256.6666666667px #ff0080, -55px -97.6666666667px #00ff99, 25px -406.6666666667px #dd00ff, -196px 79.3333333333px #00ff5e, -28px -312.6666666667px #2b00ff, -146px 70.3333333333px #00ff99, 110px -234.6666666667px #ff3300, 161px 40.3333333333px #00ff55, 14px -0.6666666667px #ff002f, -211px -53.6666666667px red, 170px 6.3333333333px #0900ff, 176px -201.6666666667px #2600ff, 84px -331.6666666667px #0022ff, 68px -154.6666666667px #008cff, -133px 62.3333333333px #00ff1e, 2px 43.3333333333px #00f2ff, 29px 65.3333333333px #0062ff, -229px 9.3333333333px #ff6600, -202px -390.6666666667px #00ffd9, 237px -264.6666666667px #a2ff00, 101px -78.6666666667px #88ff00, 243px -355.6666666667px #0077ff, 166px 50.3333333333px #00ffaa, 185px -354.6666666667px #00ffdd, -236px -116.6666666667px #ff005e, -12px -77.6666666667px #ff4000, 243px -148.6666666667px #ffa600;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: -202px -87.6666666667px #00ff4d, 248px 65.3333333333px #0051ff, 216px -344.6666666667px #ff0059, -85px 29.3333333333px #1e00ff, -178px -315.6666666667px #3700ff, -116px -25.6666666667px #66ff00, 149px -112.6666666667px #09ff00, -170px -240.6666666667px #00fff2, -67px -349.6666666667px #f700ff, -115px -30.6666666667px #3700ff, -162px -334.6666666667px #00ff15, -31px 75.3333333333px #a600ff, -120px -279.6666666667px #59ff00, 249px -223.6666666667px #ff5100, -5px -227.6666666667px #ff00bf, -183px -71.6666666667px #ff009d, 189px -114.6666666667px #00ffbb, -57px -341.6666666667px #e600ff, 3px -88.6666666667px #ff00d0, 192px -352.6666666667px #59ff00, 34px -360.6666666667px #ff002b, 105px -12.6666666667px #d0ff00, -73px -37.6666666667px #bfff00, 28px -243.6666666667px #1a00ff, 15px -256.6666666667px #ff0080, -55px -97.6666666667px #00ff99, 25px -406.6666666667px #dd00ff, -196px 79.3333333333px #00ff5e, -28px -312.6666666667px #2b00ff, -146px 70.3333333333px #00ff99, 110px -234.6666666667px #ff3300, 161px 40.3333333333px #00ff55, 14px -0.6666666667px #ff002f, -211px -53.6666666667px red, 170px 6.3333333333px #0900ff, 176px -201.6666666667px #2600ff, 84px -331.6666666667px #0022ff, 68px -154.6666666667px #008cff, -133px 62.3333333333px #00ff1e, 2px 43.3333333333px #00f2ff, 29px 65.3333333333px #0062ff, -229px 9.3333333333px #ff6600, -202px -390.6666666667px #00ffd9, 237px -264.6666666667px #a2ff00, 101px -78.6666666667px #88ff00, 243px -355.6666666667px #0077ff, 166px 50.3333333333px #00ffaa, 185px -354.6666666667px #00ffdd, -236px -116.6666666667px #ff005e, -12px -77.6666666667px #ff4000, 243px -148.6666666667px #ffa600;
  }
}
@keyframes bang {
  to {
    box-shadow: -202px -87.6666666667px #00ff4d, 248px 65.3333333333px #0051ff, 216px -344.6666666667px #ff0059, -85px 29.3333333333px #1e00ff, -178px -315.6666666667px #3700ff, -116px -25.6666666667px #66ff00, 149px -112.6666666667px #09ff00, -170px -240.6666666667px #00fff2, -67px -349.6666666667px #f700ff, -115px -30.6666666667px #3700ff, -162px -334.6666666667px #00ff15, -31px 75.3333333333px #a600ff, -120px -279.6666666667px #59ff00, 249px -223.6666666667px #ff5100, -5px -227.6666666667px #ff00bf, -183px -71.6666666667px #ff009d, 189px -114.6666666667px #00ffbb, -57px -341.6666666667px #e600ff, 3px -88.6666666667px #ff00d0, 192px -352.6666666667px #59ff00, 34px -360.6666666667px #ff002b, 105px -12.6666666667px #d0ff00, -73px -37.6666666667px #bfff00, 28px -243.6666666667px #1a00ff, 15px -256.6666666667px #ff0080, -55px -97.6666666667px #00ff99, 25px -406.6666666667px #dd00ff, -196px 79.3333333333px #00ff5e, -28px -312.6666666667px #2b00ff, -146px 70.3333333333px #00ff99, 110px -234.6666666667px #ff3300, 161px 40.3333333333px #00ff55, 14px -0.6666666667px #ff002f, -211px -53.6666666667px red, 170px 6.3333333333px #0900ff, 176px -201.6666666667px #2600ff, 84px -331.6666666667px #0022ff, 68px -154.6666666667px #008cff, -133px 62.3333333333px #00ff1e, 2px 43.3333333333px #00f2ff, 29px 65.3333333333px #0062ff, -229px 9.3333333333px #ff6600, -202px -390.6666666667px #00ffd9, 237px -264.6666666667px #a2ff00, 101px -78.6666666667px #88ff00, 243px -355.6666666667px #0077ff, 166px 50.3333333333px #00ffaa, 185px -354.6666666667px #00ffdd, -236px -116.6666666667px #ff005e, -12px -77.6666666667px #ff4000, 243px -148.6666666667px #ffa600;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(250px);
    -moz-transform: translateY(250px);
    -webkit-transform: translateY(250px);
    -o-transform: translateY(250px);
    -ms-transform: translateY(250px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(250px);
    -moz-transform: translateY(250px);
    -webkit-transform: translateY(250px);
    -o-transform: translateY(250px);
    -ms-transform: translateY(250px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(250px);
    -moz-transform: translateY(250px);
    -webkit-transform: translateY(250px);
    -o-transform: translateY(250px);
    -ms-transform: translateY(250px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 20%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 20%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 20%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 20%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 20%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 20%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 20%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 20%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 20%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 20%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

/*fireworkk end */
/*parallex effect start */

.normalsection {
  padding: 25vh 10%;
  position: relative;
  min-height: 100vh;
  width: 100vw;
  transform-style: inherit;
  /*-moz-animation: shake 35000ms 1 linear;
    -o-animation: shake 35000ms 1 linear;    
    -webkit-animation: shake 35000ms 1 linear;
    animation: shake 35000ms 1 linear;
    */
}

.normalsection, .normalsection:before {
  background: 50% 50% / cover;
  box-sizing: border-box;
}

.normalsection:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}



.shakingsection {
 
  position: relative;
  min-height: 100vh;
  width: 100vw;
  transform-style: inherit;
 
    
}

.shakingsection, .shakingsection:before {
  background: 50% 50% / cover;
  background-origin: border-box;
  

}

.shakingsection:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
}

/* The brightest duotone color */
.darken::before {
  background-color: rgba(221, 204, 0, 1);
}

/* The darkest duotone color */
.lighten::after {
  background-color: rgba(221, 0, 0, 1);
}



@media screen and (-webkit-min-device-pixel-ratio: 2) {
    #section-5:before {
        background-attachment: scroll;
    }
}



/*carousel  section images start*/

#carousel-hero-5:before {
  background-image: url("../images/test23.jpeg");
  transform: translateZ(-1px) scale(1);
  -webkit-transform:translateZ(-1px) scale(1);
  z-index: -1;
  

  

}





#hero-8{
  position: relative;
  min-height: 80%;
  width: 100%;
  transform-style: inherit;

  
 
  /* -moz-animation: spinning-poster2  20s 1s linear infinite;
  -o-animation: spinning-poster2  20s 1s linear infinite;    
  -webkit-animation: spinning-poster2  20s 1s linear infinite;
  animation: spinning-poster2  20s 1s linear infinite;
  -webkit-animation-delay: 10s;
  animation-delay: 10s;  */
  -webkit-transform-origin:  30% 55%;
  transform-origin: 30% 55%;
  -webkit-transform: scale(1.4) perspective(1000px) rotateZ(0deg) rotateY(0deg) rotateX(-20deg);
  transform:  scale(1.4) perspective(1000px) rotateZ(0deg) rotateY(0deg) rotateX(-20deg);
  
}
#hero-8:before,
#hero-8:after {
  content: "";
  position: relative;
  top: 0;
  width: 0;
  min-height: 100%;
  box-sizing: border-box;
  background: transparent;
  border-top: 4px solid white;
  border-bottom: 4px solid white;

  border-right: 6px solid rgba(0, 0, 0, 0.5);
  
}
#hero-8:before {
  left: calc(10% - 7px);
}
#hero-8:after {
  right: calc(55% - 7px);

}

.alignleftclass{
 
}

#carousel-hero-1:before {
  background-image: url("../images/test16.jpeg");
  transform: translateZ(-1px) scale(1);
  -webkit-transform:translateZ(-1px) scale(1);
  z-index: -1;
  box-sizing: border-box;
  /*left:15vw ;*/
 
  transform-style: preserve-3d;
  /* -webkit-transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
  transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg); */

  -moz-animation: spinning-circle2  10s 2 linear;
  -o-animation: spinning-circle2  10s 2 linear;    
  -webkit-animation: spinning-circle2  10s 2 linear;
  animation: spinning-circle2  10s 2 linear;

}

#carousel-hero-2:before {
  background-image: url("../images/test9.jpeg");
  transform: translateZ(-1px) scale(1);
  -webkit-transform:translateZ(-1px) scale(1);

  box-sizing: border-box;
  /*left:15vw ;*/
  z-index: -2;
  transform-style: preserve-3d;
  /* -webkit-transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
  transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg); */
  -moz-animation: spinning-circle2  10s 2 linear;
  -o-animation: spinning-circle2  10s 2 linear;    
  -webkit-animation: spinning-circle2  10s 2 linear;
  animation: spinning-circle2  10s 2 linear;
}

#carousel-hero-3:before {
  background-image: url("../images/test1.jpeg");
  transform: translateZ(-1px) scale(1.5);
  -webkit-transform:translateZ(-1px) scale(1.5);
 

  box-sizing: border-box;
  /*left:15vw ;*/
  transform-style: preserve-3d;
  /* -webkit-transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
  transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg); */
  z-index: -3;
  -moz-animation: spinning-circle2  10s 2 linear;
  -o-animation: spinning-circle2  10s 2 linear;    
  -webkit-animation: spinning-circle2  10s 2 linear;
  animation: spinning-circle2  10s 2 linear;
}

#carousel-hero-4:before {
  background-image: url("../images/test.jpeg");
  transform: translateZ(-1px) scale(2);
  -webkit-transform:translateZ(-1px) scale(2);
  margin-left: 20rem;
  z-index: -4;

  
  box-sizing: border-box;
  transform-style: preserve-3d;
  -webkit-transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
  transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
}

.login4thsection {
 
  position: relative;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  transform-style: inherit;
 
    
}

.login4thsection, .login4thsection:before {
  background: 50% 50% / cover;
  background-origin: border-box;
  

}

.login4thsection:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
}
/*carousel section images end*/
.carousel-control-next-icon:after
{
  content: '>';
  font-size: 1px;
  color: #961ec5;
  z-index: 21;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 1px;
  color: #961ec5;
  z-index: 21;
}

/*section spaer */
.spcerclas {
 
  position: relative;


}
/*test section */
#hero-test:before {
  background-image: url("../images/prallax-test.jpeg");
  box-sizing: border-box;
  transform-style: preserve-3d;
  
}


/* The hero image */
.hero-imageposter {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image:  url("../images/test23.jpeg");

  /* Set a specific height */
  
  
  max-width: 100%;
  
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;

  transform-style: preserve-3d;
  -webkit-transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) translateZ(-1px) scale(1);
  transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) translateZ(-1px) scale(1);
}



/* Place text in the middle of the image */
.hero-imagepostertext {
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  
}

/*moving text  start*/
.loginMoving-text {
 
  
  position: relative;

  text-transform: uppercase;
  font: 700 20px/1 "Arvo", sans-serif;
  text-align: left;
  margin-left: 10rem;
  top: 5rem;
 
  -webkit-animation: in 15s ease-in-out forwards infinite;
          animation: in 15s ease-in-out  forwards infinite;
  -webkit-animation-delay: 20s;
          animation-delay: 20s;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
  -webkit-transform:  perspective(1000px)  rotateZ(1deg);
  transform:  perspective(1000px)  rotateZ(1deg) ;
  max-width: 100vw;
  z-index: 12;

}


.loginMoving-quotes-text{
 
  
 

  text-transform: uppercase;
  font: 700 40px/1 "Arvo", sans-serif;
  text-align: center;
  margin-left: 5rem;
  
 
  /*-webkit-animation: quoteEffect 15s ease-in-out forwards infinite;
          animation: quoteEffect 15s ease-in-out  forwards infinite;
  -webkit-animation-delay: 20s;
          animation-delay: 20s;*/
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);

  max-width: 100vw;
  z-index: 12;
  
  transform: translate(0, -50%) scale(1, 1.3);
  -webkit-transform:  perspective(1000px) rotateZ(-5deg) ;
  transform:  perspective(1000px) rotateZ(-5deg) ;
  
}

@-webkit-keyframes in {
  0% {
    letter-spacing: 10px;
  }
  100% {
    letter-spacing: 5px;
  }
}

@keyframes in {
  0% {
    letter-spacing: 10px;
  }
  100% {
    letter-spacing: 5px;
  }
}


@-webkit-keyframes quoteEffect {
  0% {
    letter-spacing: 20px;
  }
  100% {
    letter-spacing: 5px;
  }
}

@keyframes quoteEffect {
  0% {
    letter-spacing: 20px;
  }
  100% {
    letter-spacing: 5px;
  }
}
/*moving text end*/
/*parallex effect end */
/*book section start */
#booksection{
  

  transform: translate(-50%, -50%);
  box-sizing: border-box;
  transform-style: preserve-3d;
  position: sticky;
  position: -webkit-sticky;
  scroll-behavior: none;
  
  -webkit-transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(1deg);
  transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(1deg);
  z-index: 20;
}
/*book section end*/

.poster3d{

  position: relative;
  font-family: Arial;
  margin-right: 30vw;
  margin-top: 11vw;
  margin-left: 20vw;
  -webkit-transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(1deg);
  transform:  perspective(1000px) rotateY(30deg) rotateX(0deg) rotateZ(0deg);
}
.cutecontainer{

  position: relative;
  font-family: Arial;

}

.cutecontainer-text-block {
  
  text-transform: uppercase;
  font: 700 24px/1 "Arvo", sans-serif;
  position: absolute;
bottom: -100px;
text-align: center;
  background-color: #00f2ff;
  color: white;
  padding-left: 1px;
  padding-right: 20px;
  width: 40%;
  height: 20%;
  
  box-sizing: border-box;

}



.hollywoodtext {
  text-align: center;
  color: #0022ff;
  font-family: "Permanent Marker", cursive;
  font-size: 20px;
}

.cutecontainer-text-block-right {
  
  text-transform: uppercase;
  font: 700 24px/1 "Arvo", sans-serif;
  position: absolute;
bottom: 100px;
text-align: center;
  background-color: white ;
  color: transparent;
  padding-left: 1px;
  padding-right: 20px;
  width: 20%;
  height: 10%;
  right: 40px;
  box-sizing: border-box;

  -webkit-transform-origin: 80% 15%;;
  transform-origin: 50% 5%;;
  -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-55deg) rotateX(10deg);

}
.spinbrand{


  -moz-animation: spinning-circle3  10s 5s linear infinite;
  -o-animation: spinning-circle3  10s 5s linear infinite;    
  -webkit-animation: spinning-circle3  10s 5s linear infinite;
  animation: spinning-circle3 10s 5s linear infinite;
}

.spinbrand::before{

  content:''; position:absolute; 
  height:100%; width:100%; 
  animation:rotate 3s linear reverse infinite;
}

.collapsed{
  -moz-animation: move-1  45s 1s ease-in-out infinite;
  -o-animation: move-1  45s 1s  ease-in-out infinite;    
  -webkit-animation: move-1  45s 1s  ease-in-out infinite;
  animation: move-1 45s 1s  ease-in-out infinite;
  z-index: 500;
}
@keyframes rotate { 100% { transform:rotate(-360deg); } }

@media (min-width: 768px) {
  .hollywoodtext {
    font-size: 2vw;
  }
}


@keyframes spinning-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 5% 5%;
    transform-origin: 5% 5%;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 5% 5%;
    transform-origin: 5% 5%;


  }
}


@keyframes spinning-circle2 {
  0% {

    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    z-index: 99;
  }
  20% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
  30% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
  100% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
}

@-webkit-keyframes spinning-circle2 {
  0% {

    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    z-index: 99;
  }
  20% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
  30% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
  100% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
}

@keyframes spinning-circle3 {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
  
}

@-webkit-keyframes spinning-circle3 {
  from {
    -webkit-transform:rotate(0deg);
  }

  to {
    -webkit-transform:rotate(360deg);
  }
}
 /*sprin circle end */
 /*brand flip image start*/

 #walkinglioncircle {
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/Lion-walk.gif") ;
 background-size: 80% 40%;
 margin-left: 10px;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

 width: 100%;  overflow: hidden; z-index: 3;
 -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite;
 

}



#sittingLion {
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/forest3d.jpeg") ;
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 

}
#sittingLion2{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/flower2.gif") ; */
  background: url("../images/city8.jpeg") ; 
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#sittingLion3{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/waterfall-3d.jpeg") ;
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#forestsection {
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/forest2.gif") ;
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;

 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 

}

#postersection{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/city3.jpeg") ;
  background-size: 100% 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 
   width: 100%;  overflow: hidden; z-index: 0;
   height:100vh;
   width: 100vw;
  /*-moz-animation: move-1  30s 1s ease-in-out infinite;
  -o-animation: move-1  30s 1s  ease-in-out infinite;    
  -webkit-animation: move-1  30s 1s  ease-in-out infinite;
  animation: move-1 30s 1s   ease-in-out infinite; */

}
#postersection2{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/city7.jpeg") ;
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}
#postersection4{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/city6.jpeg") ;
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}
#postersection3{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/city3.jpeg") ;
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

.postercuteimage
{
  background: url("../images/test23-2.jpeg") ;
  background-size: 40% 100%;
 
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 z-index: 1;
  width: 50%;  overflow: hidden; 
  transform: scale(1) rotateY(0deg);
  -webkit-transform-origin: 65% 65%;
  transform-origin: -30% 70%;
 
  height:100vh;
  width: 100vw;
  max-width: 100vw;

  margin-right: 30vw;
  margin-top: 5vw;
  margin-left: 20vw;
  -webkit-transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(1deg);
  transform:  perspective(1000px) rotateY(10deg) rotateX(0deg) rotateZ(0deg);
  

}

#officeenvironment{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url("../images/ownoffice1.jpeg") ;
 
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

@media  screen and (max-width: 600px) {
  /* Small screen, non-retina */
  .cat-bed3{
    background: url("../images/Lion3.gif") ;
    background-size: contain;

  }

  #seawelcome-shark{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/ocean-frontscreen-shark2.gif") ;
   background-size: contain;
  
  }
  
  
  
  #seawelcome4{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/ocean-frontscreen-dolphin.gif") ;
   background-size: contain;
  
  }
  
  #seawelcome3{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/ocean-frontscreen-ship.gif") ;
   background-size: contain;
  
  }
  
  
  #seawelcome2{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/ocean-frontscreen-ship-waves.gif") ;
   background-size: contain;
  
  }
  
  
  #seawelcome{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/ocean-frontscreen-waves.gif") ;
   background-size: contain;
  
  }
  
  #officebuilding{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/office-building.jpeg") ;
   background-size: contain;
   position:fixed ;

  
   
  }
  
  #officebuilding2{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/ownoffice2.jpeg") ;
   background-size: contain;
   position:fixed ;
  }
  
  #officebuilding3{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/test26.jpeg") ;
   background-size: contain;
   position:fixed ;
  }
  
  #officebuilding4{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/ownoffice-5.jpeg") ;
   background-size: contain;
   position:fixed ;
  }
  
  
  #officescreen-rupa{
    background: url("../images/rupa-10.jpeg") ;    
   background-size: contain;
   position:fixed ;
  
  }
  
  #officescreen-rupa2{
    background: url("../images/Test103-4.jpeg") ;
   background-size: contain;
   position:fixed ;
   
  }
  #circle-rupa{
    background: url("../images/rupa-10.jpeg") ;
    background-size: contain;
   position:fixed ;
   bottom: 0;
  }
 
}
/* end of media screen*/

#seawelcome-shark{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/ocean-frontscreen-shark2.gif") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}



#seawelcome4{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/ocean-frontscreen-dolphin.gif") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#seawelcome3{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/ocean-frontscreen-ship.gif") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}


#seawelcome2{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/ocean-frontscreen-ship-waves.gif") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}


#seawelcome{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/ocean-frontscreen-waves.gif") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}
#lastSection{

 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
}

#officebuilding{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/office-building.jpeg") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 animation:  changePage5BackgroundImage  30s infinite ease-in-out;

}


#changebgimage{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/test15-rm.jpeg") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 animation: 50s changeBackgroundImage2 infinite linear;

}
#officebuilding2{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/ownoffice2.jpeg") ;
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#officebuilding3{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/test26.jpeg") ;
 background-size: 100% 200%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#officebuilding4{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url("../images/ownoffice-5.jpeg") ;
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}


#officescreen-rupa{
  background: url("../images/rupa-10.jpeg") ;
  
 background-size: 100% 100%;
 
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
  transform: scale(4) rotateY(100deg);
  -webkit-transform-origin: 65% 65%;
  transform-origin: 65% 65%;
 margin-right: auto;


 

 top: 1;
 bottom: 0;
 left: 0;
 right: 0;
z-index: 1;
 
height:100vh;
width: 100vw;

}

#officescreen-rupa2{
  background: url("../images/Test103-4.jpeg") ;
  

 background-size: 80% 100%;
 
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
z-index: 1;
 width: 50%;  overflow: hidden; 
 transform: scale(1) rotateY(0deg);
 -webkit-transform-origin: 65% 65%;
 transform-origin: -30% 70%;

 height:100vh;
 width: 100vw;
 
}

#officescreen-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#officescreen-coloumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 426px) {


}

@media screen and (max-width: 426px) {
  #officeenvironment{
    background-size: 100% 50%;
  }

  #officebuilding2{
   background-size: 100% 60%;
  }
 }
@media screen and (min-width: 769px) {
  #officeenvironment{
  }
}

#circle-rupa{
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/Lion3.gif") ; */
  background: url("../images/rupa-10.jpeg") ;
  
 background-size: 60% 80%;
 
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
z-index: 1;
 width: 70%;  overflow: hidden; 
 -moz-animation: rupamove  30s 1s ease-in-out infinite;
 -o-animation: rupamove  30s 1s  ease-in-out infinite;    
 -webkit-animation: rupamove  30s 1s  ease-in-out infinite;
 animation: rupamove 30s 1s   ease-in-out infinite;         
 

}


 #circle {
  
   /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
   /* background: url("../images/Lion3.gif") ; */
   background: url("../images/Lion3.gif") ;
   
  background-size: 60% 30%;
  
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 
  width: 50%;  overflow: hidden; z-index: 3;
  -moz-animation: move-1  30s 1s ease-in-out infinite;
  -o-animation: move-1  30s 1s  ease-in-out infinite;    
  -webkit-animation: move-1  30s 1s  ease-in-out infinite;
  animation: move-1 30s 1s   ease-in-out infinite;
  

}


 #circle text {
  
  font-family: 'Helvetica Neue', Arial; font-size: 20px; font-weight: bold; 

}
 

 #circle svg { 
   
  position: relative;   height: inherit; z-index: 0;


   -webkit-animation-name: rotate;
      -moz-animation-name: rotate;
       -ms-animation-name: rotate;
        -o-animation-name: rotate;
           animation-name: rotate;
   -webkit-animation-duration: 5s;
      -moz-animation-duration: 5s;
       -ms-animation-duration: 5s;
        -o-animation-duration: 5s;
           animation-duration: 5s;
   -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
       -ms-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
           animation-iteration-count: infinite;
   -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
       -ms-animation-timing-function: linear;
        -o-animation-timing-function: linear;
           animation-timing-function: linear;
 
 }
 
 @-webkit-keyframes rotate {
     from { -webkit-transform: rotate(360deg); }
     to { -webkit-transform: rotate(0); }
 }
 @-moz-keyframes rotate {
     from { -moz-transform: rotate(360deg); }
     to { -moz-transform: rotate(0); }
 }
 @-ms-keyframes rotate {
     from { -ms-transform: rotate(360deg); }
     to { -ms-transform: rotate(0); }
 }
 @-o-keyframes rotate {
     from { -o-transform: rotate(360deg); }
     to { -o-transform: rotate(0); }
 }
 @keyframes rotate {
     from { transform: rotate(360deg); }
     to { transform: rotate(0); }
 }
 
 @-webkit-keyframes move-1{
  0%{
    transform: scale(3) rotate(0deg);
     -webkit-transform-origin: 35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(-5deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(-5deg);
     -webkit-transform-origin:  35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  
    }
  
  }
  @-moz-keyframes move-1{
    0%{
      transform: scale(3) rotate(0deg);
       -webkit-transform-origin: 35% 45%;
       transform-origin: 35% 45%;
      margin-right: auto;
    }
      /* Finish changes by here */
  
      40% {
        transform: scale(3) rotate(5deg);
        -webkit-transform-origin: 35% 45%;
        transform-origin: 35% 45%;
       margin-right: auto;
      }
    
      50% {
        transform: scale(3) rotate(0deg);
        -webkit-transform-origin: 35% 45%;
        transform-origin: 35% 45%;
       margin-right: auto;
      }
      60%{
        transform: scale(3) rotate(-5deg);
        -webkit-transform-origin:  35% 45%;
        transform-origin: 35% 45%;
       margin-right: auto;
     
       }
       70%{
        transform: scale(3) rotate(0deg);
        -webkit-transform-origin:  35% 45%;
        transform-origin: 35% 45%;
       margin-right: auto;
     
       }
  
      100%{
       transform: scale(3) rotate(-5deg);
       -webkit-transform-origin:  35% 45%;
       transform-origin: 35% 45%;
      margin-right: auto;
    
      }
      
    
    
    }
 @-ms-keyframes  move-1{
  0%{
    transform: scale(3) rotate(0deg);
     -webkit-transform-origin: 35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(-5deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(-5deg);
     -webkit-transform-origin:  35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  
    }
  
  }
 @-o-keyframes move-1{
  0%{
    transform: scale(3) rotate(0deg);
     -webkit-transform-origin: 35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(-5deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(-5deg);
     -webkit-transform-origin:  35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  
    }
  
  }
 
 @keyframes move-1{
  0%{
    transform: scale(3) rotate(0deg);
     -webkit-transform-origin: 35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(0deg);
     -webkit-transform-origin:  35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  
    }
  
  }

  /*keep original for lion
  
   
 @keyframes move-1{
  0%{
    transform: scale(3) rotate(0deg);
     -webkit-transform-origin: 35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  }
    

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(-5deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(-5deg);
     -webkit-transform-origin:  35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  
    }
  
  }
  
  
  
  */
 /*brand flip image end*/
 /*menu 3d start */
 ul {
  position: relative;
  transform: rotate(-5deg) skew(180deg, 5deg);
}

.list-item {
  background: #a600ff;
  color: #575757;
  text-align: center;
  height: 2.5em;
  width: 6em;
  vertical-align: left;
  line-height: 2.5em;
  border-bottom: 1px solid #060606;
  position: relative;
  display: block;
  text-decoration: none;
  /* box-shadow: -2em 1.5em 0 #e1e1e1; */
  transition: all 0.25s linear;
}
.list-item:hover {
  background: #ff6e42;
  color: #fffcfb;
  transform: translate(0.9em, -0.9em);
  transition: all 0.25s linear;
  box-shadow: -2em 2em 0 #e1e1e1;
}
.list-item:hover:before, .list-item:hover:after {
  transition: all 0.25s linear;
}
.list-item:hover:before {
  background: #b65234;
  width: 1em;
  top: 0.5em;
  left: -1em;
}
.list-item:hover:after {
  background: #b65234;
  width: 1em;
  bottom: -2.5em;
  left: 1em;
  height: 4em;
}
.list-item:before, .list-item:after {
  content: "";
  position: absolute;
  transition: all 0.25s linear;
  width: 0.5em;
}
.list-item:after {
  height: 4em;
  background: #181818;
  bottom: -2.25em;
  left: 1.5em;
  transform: rotate(90deg) skew(0, 45deg);
}
.list-item:before {
  height: 2.5em;
  background: #121212;
  top: 0.25em;
  left: -0.5em;
  transform: skewY(-45deg);
}

 /*menu 3d end */

 /*tiger walk*/
 .tuna {
  /* steps causes the animation to chunk into 12 equal pieces */
  animation: walk-cycle 2s steps(12) infinite;
  background: url(../images/tiger-phases.jpeg) 0 0 no-repeat; 
  height: 100px;
  width: 200px;
  margin: 100px auto 0;
}

@keyframes walk-cycle {  
  0% {background-position: 0 0; } 
  100% {background-position: 0 -2391px; } /* Must be full height of sprite or skipping will happen.*/
}

.walking
{
   


}

.cat-bed3
{
  background: url("../images/Lion3.gif") ;
  background-size: 70% 100%;
 
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 z-index: 1;
  width: 50%;  overflow: hidden; 
  transform: scale(1) rotateY(0deg);
  -webkit-transform-origin: 65% 65%;
  transform-origin: -30% 70%;
 
  height:100vh;
  width: 100vw;
  max-width: 100vw;
  

}


.cat-bed2
{
  background: url("../images/tigre-gif-3-animation.gif") ;
   height: 400px;
   width: 400px;
   top: 1%;
   margin-right: 1rem;
   z-index: 300;
    -webkit-animation:tigermove 15s steps(15) infinite;
    -moz-animation:tigermove 15s steps(15) infinite;
    -ms-animation:tigermove 15s steps(15) infinite;
    -o-animation:tigermove 15s steps(15) infinite;
    animation:tigermove 25s steps(25) infinite;
}



.cat-bed
{
  background: url("../images/tiger3d-nobg.jpeg") ;
   height: 500px;
   width: 500px;
   top: 1%;
   margin-right: 1rem;
   z-index: 300;
 
    -webkit-animation:tigermove 15s steps(15) infinite;
    -moz-animation:tigermove 15s steps(15) infinite;
    -ms-animation:tigermove 15s steps(15) infinite;
    -o-animation:tigermove 15s steps(15) infinite;
    animation:tigermove 25s steps(25) infinite;
}

.cat-bed text {
  
  font-family: 'Helvetica Neue', Arial; font-size: 30px; font-weight: bold; 

}

.cat-bed svg { 
   
  position: relative;   height: inherit; z-index: 0;
  top:-9rem;
  left: 2rem;
  right: auto;
  bottom: 0;

  /* -webkit-animation-name: rotate;
      -moz-animation-name: rotate;
       -ms-animation-name: rotate;
        -o-animation-name: rotate;
           animation-name: rotate;
   -webkit-animation-duration: 5s;
      -moz-animation-duration: 5s;
       -ms-animation-duration: 5s;
        -o-animation-duration: 5s;
           animation-duration: 5s;
   -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
       -ms-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
           animation-iteration-count: infinite;
   -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
       -ms-animation-timing-function: linear;
        -o-animation-timing-function: linear;
           animation-timing-function: linear;
 */
 }
 


@keyframes tigermove
{  
  0% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px)  15deg} 
  40% {margin-right: 100%; -webkit-margin-end:50% ;transform:  perspective(1000px) rotateY(-15deg) }
 
  100% {margin-right: 100%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(15deg) } 
}

@-moz-keyframes tigermove 
{
  0% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px)  15deg} 
  40% {margin-right: 0%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(-15deg) }
  50% {margin-right: 0%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(-175deg) }
  50% {margin-right: 20%; -webkit-margin-end:20% ;transform:  perspective(1000px) rotateY(-175deg) }
  100% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px) rotateY(-175deg) } 
}
@-ms-keyframes tigermove
{
  0% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px)  15deg} 
  40% {margin-right: 0%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(-15deg) }
  50% {margin-right: 0%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(-175deg) }
  50% {margin-right: 20%; -webkit-margin-end:20% ;transform:  perspective(1000px) rotateY(-175deg) }
  100% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px) rotateY(-175deg) } 
}

@-o-keyframes tigermove
{
  0% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px)  15deg} 
  40% {margin-right: 0%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(-15deg) }
  50% {margin-right: 0%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(-175deg) }
  50% {margin-right: 20%; -webkit-margin-end:20% ;transform:  perspective(1000px) rotateY(-175deg) }
  100% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px) rotateY(-175deg) } 
}   

@-webkit-keyframes tigermove 
{
  0% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px)  15deg} 
  40% {margin-right: 0%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(-15deg) }
  50% {margin-right: 0%; -webkit-margin-end:0% ;transform:  perspective(1000px) rotateY(-175deg) }
  50% {margin-right: 20%; -webkit-margin-end:20% ;transform:  perspective(1000px) rotateY(-175deg) }
  100% {margin-right: 100%; -webkit-margin-end:100% ;transform:  perspective(1000px) rotateY(-175deg) } 
}


@keyframes tigerwalk
{  
    from {background-position: 0 -2391px; } 
    to {background-position:  0 0;}
}

@-moz-keyframes tigerwalk 
{
    from {background-position: 0 0; } 
    to {background-position: 0 -2391px; }
}

@-ms-keyframes tigerwalk
{
    from {background-position: 0 0; } 
    to {background-position: 0 -2391px; }
}

@-o-keyframes  tigerwalk
{
    from {background-position: 0 0; } 
    to {background-position: 0 -2391px; }
}   

@-webkit-keyframes tigerwalk 
{
    from {background-position: 0 0; } 
    to {background-position: 0 -2391px; }
}


@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% {
    -webkit-transform: scale(0.3) translateY(-100vw);
            transform: scale(0.3) translateY(-100vw);
  }
  10% {
    -webkit-transform: translateY(2vh) translateY(10vw) scale(0.4);
            transform: translateY(2vh) translateY(10vw) scale(0.4);
  }
  20% {
    -webkit-transform: translateY(0vh) translateY(30vw) scale(0.5);
            transform: translateY(0vh) translateY(30vw) scale(0.5);
  }
  30% {
    -webkit-transform: translateY(4vh) translateY(50vw) scale(0.6);
            transform: translateY(4vh) translateY(50vw) scale(0.6);
  }
  40% {
    -webkit-transform: translateY(2vh) translateY(70vw) scale(0.6);
            transform: translateY(2vh) translateY(70vw) scale(0.6);
  }
  50% {
    -webkit-transform: translateY(0vh) translateY(90vw) scale(0.6);
            transform: translateY(0vh) translateY(90vw) scale(0.6);
  }
  60% {
    -webkit-transform: translateY(0vh) translateY(110vw) scale(0.6);
            transform: translateY(0vh) translateY(110vw) scale(0.6);
  }
  100% {
    -webkit-transform: translateY(0vh) translateY(110vw) scale(0.6);
            transform: translateY(0vh) translateY(110vw) scale(0.6);
  }
}
 /*tiger walk end*/

 /*rupamove start*/
 @-webkit-keyframes rupamove{
  0%{
    transform: scale(3) rotate(5deg);
     -webkit-transform-origin: 35% 65%;
     transform-origin: 35% 65%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin:  35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(5deg);
     -webkit-transform-origin:  35% 65%;
     transform-origin: 35% 65%;
    margin-right: auto;
  
    }
  
  }
  @-moz-keyframes rupamove{
    0%{
      transform: scale(3) rotate(5deg);
       -webkit-transform-origin: 35% 65%;
       transform-origin: 35% 65%;
      margin-right: auto;
    }
      /* Finish changes by here */
  
      40% {
        transform: scale(3) rotate(5deg);
        -webkit-transform-origin: 35% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
      }
    
      50% {
        transform: scale(3) rotate(5deg);
        -webkit-transform-origin: 35% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
      }
      60%{
        transform: scale(3) rotate(5deg);
        -webkit-transform-origin:  35% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
     
       }
       70%{
        transform: scale(3) rotate(5deg);
        -webkit-transform-origin: 35% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
     
       }
  
      100%{
       transform: scale(3) rotate(5deg);
       -webkit-transform-origin:  35% 65%;
       transform-origin: 35% 65%;
      margin-right: auto;
    
      }
    
    }
 @-ms-keyframes  rupamove{
  0%{
    transform: scale(3) rotate(5deg);
     -webkit-transform-origin: 35% 65%;
     transform-origin: 35% 65%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin:  35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(5deg);
     -webkit-transform-origin:  35% 65%;
     transform-origin: 35% 65%;
    margin-right: auto;
  
    }
  
  }
 @-o-keyframes rupamove{
  0%{
    transform: scale(3) rotate(5deg);
     -webkit-transform-origin: 35% 65%;
     transform-origin: 35% 65%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin:  35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(5deg);
     -webkit-transform-origin:  35% 65%;
     transform-origin: 35% 65%;
    margin-right: auto;
  
    }
  
  }
 
 @keyframes rupamove{
  0%{
    transform: scale(3) rotate(5deg);
     -webkit-transform-origin: 45% 65%;
     transform-origin: 45% 65%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin:  45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(5deg);
     -webkit-transform-origin:  45% 65%;
     transform-origin: 45% 65%;
    margin-right: auto;
  
    }
  
  }

 /*rupa move end*/



 /*humming bird*/
 #humming-bird{
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/Lion3.gif") ; */
  background: url("../images/humming-bird2-2.gif") ;
  
 background-size: 50% 10%;
 
 background-repeat: no-repeat;
 background-position: center right;
 background-color: transparent;
 position: absolute; 
 margin-left: 5em;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

 width: 50%;  overflow: hidden; z-index: 3;
  -moz-animation: loginzoom  30s 1s ease-in-out infinite;
 -o-animation: loginzoom  30s 1s  ease-in-out infinite;    
 -webkit-animation: loginzoom  30s 1s  ease-in-out infinite;
 animation: loginzoom 30s 1s   ease-in-out infinite; 
 

}

#humming-bird2{
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/Lion3.gif") ; */
  background: url("../images/humming-bird2-2.gif") ;
  
 background-size: 50% 10%;
 
 background-repeat: no-repeat;
 background-position: center right;
 background-color: transparent;
 position: absolute; 
 margin-left: 5em;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

 width: 10%;  overflow: hidden; z-index: 3;
  -moz-animation: loginzoom  30s 1s ease-in-out infinite;
 -o-animation: loginzoom  30s 1s  ease-in-out infinite;    
 -webkit-animation: loginzoom  30s 1s  ease-in-out infinite;
 animation: loginzoom 30s 1s   ease-in-out infinite; 
 

}

.LoginButton-availability{

/* 
   overflow: hidden; z-index: 99;
 -moz-animation: loginzoom  30s 1s ease-in-out infinite;
 -o-animation: loginzoom  30s 1s  ease-in-out infinite;    
 -webkit-animation: loginzoom  30s 1s  ease-in-out infinite;
 animation: loginzoom 30s 1s   ease-in-out infinite;
  */

}

@-webkit-keyframes loginzoom{

  0%{
      transform: scale(1) rotate(-10deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 55% 95%;
      margin-right: auto;
    }
  
    0%{
      transform: scale(1) rotate(0deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 65% 95%;
      margin-right: auto;
    }
      /* Finish changes by here */
  
      40% {
        transform: scale(1) rotate(5deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 5% 35%;
       margin-right: auto;
      }
    
      50% {
        transform: scale(1) rotate(25deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 0% 5%;
       margin-right: auto;
      }
      60%{
        transform: scale(1) rotate(5deg);
        -webkit-transform-origin:  45% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
     
       }
       70%{
        transform: scale(1) rotate(35deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 45% 65%;
       margin-right: auto;
     
       }
  
      100%{
       transform: scale(1) rotate(5deg);
       -webkit-transform-origin:  45% 65%;
       transform-origin: 45% 65%;
      margin-right: auto;
    
      }
  
  }
  
  @-moz-keyframes loginzoom{
  
  0%{
      transform: scale(1) rotate(-10deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 55% 95%;
      margin-right: auto;
    }
  
    0%{
      transform: scale(1) rotate(0deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 65% 95%;
      margin-right: auto;
    }
      /* Finish changes by here */
  
      40% {
        transform: scale(1) rotate(5deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 5% 35%;
       margin-right: auto;
      }
    
      50% {
        transform: scale(1) rotate(25deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 0% 5%;
       margin-right: auto;
      }
      60%{
        transform: scale(1) rotate(5deg);
        -webkit-transform-origin:  45% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
     
       }
       70%{
        transform: scale(1) rotate(35deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 45% 65%;
       margin-right: auto;
     
       }
  
      100%{
       transform: scale(1) rotate(5deg);
       -webkit-transform-origin:  45% 65%;
       transform-origin: 45% 65%;
      margin-right: auto;
    
      }
    
    
  }
  
  @-ms-keyframes  loginzoom{
  
  0%{
      transform: scale(1) rotate(-10deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 55% 95%;
      margin-right: auto;
    }
  
    0%{
      transform: scale(1) rotate(0deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 65% 95%;
      margin-right: auto;
    }
      /* Finish changes by here */
  
      40% {
        transform: scale(1) rotate(5deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 5% 35%;
       margin-right: auto;
      }
    
      50% {
        transform: scale(1) rotate(25deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 0% 5%;
       margin-right: auto;
      }
      60%{
        transform: scale(1) rotate(5deg);
        -webkit-transform-origin:  45% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
     
       }
       70%{
        transform: scale(1) rotate(35deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 45% 65%;
       margin-right: auto;
     
       }
  
      100%{
       transform: scale(1) rotate(5deg);
       -webkit-transform-origin:  45% 65%;
       transform-origin: 45% 65%;
      margin-right: auto;
    
      }
    
    
  }
  
  @-o-keyframes loginzoom{
  
  0%{
      transform: scale(1) rotate(-10deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 55% 95%;
      margin-right: auto;
    }
  
    0%{
      transform: scale(1) rotate(0deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 65% 95%;
      margin-right: auto;
    }
      /* Finish changes by here */
  
      40% {
        transform: scale(1) rotate(5deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 5% 35%;
       margin-right: auto;
      }
    
      50% {
        transform: scale(1) rotate(25deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 0% 5%;
       margin-right: auto;
      }
      60%{
        transform: scale(1) rotate(5deg);
        -webkit-transform-origin:  45% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
     
       }
       70%{
        transform: scale(1) rotate(35deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 45% 65%;
       margin-right: auto;
     
       }
  
      100%{
       transform: scale(1) rotate(5deg);
       -webkit-transform-origin:  45% 65%;
       transform-origin: 45% 65%;
      margin-right: auto;
    
      }
  }

@keyframes loginzoom{
  0%{
    transform: scale(1) rotate(-10deg);
     -webkit-transform-origin: 65% 65%;
     transform-origin: 55% 95%;
    margin-right: auto;
  }

  0%{
    transform: scale(1) rotate(0deg);
     -webkit-transform-origin: 65% 65%;
     transform-origin: 65% 95%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      transform: scale(1) rotate(5deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 5% 35%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(1) rotate(25deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 0% 5%;
     margin-right: auto;
    }
    60%{
      transform: scale(1) rotate(5deg);
      -webkit-transform-origin:  45% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(1) rotate(35deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(1) rotate(5deg);
     -webkit-transform-origin:  45% 65%;
     transform-origin: 45% 65%;
    margin-right: auto;
  
    }
  
  }


  #water-waves-section{
    background: url("../images/ocean-carribean-water.gif") ;
    
  
   background-size: 100% 100%;
   
   background-repeat: no-repeat;
   background-position: center center;
   background-color: transparent;
   position: relative; 
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
  z-index: 1;
    overflow: hidden; 
 
  
   height:100vh;
   width: 100vw;
   
  }

  #welcome-3d{
   
    height: 100%;
    /* max-height: 600px; */
    width: 1000px;
    height:100vh;
    width: 100vw;
    background-color: hsla(200, 40%, 30%, 0.4);
    background-image: 
      url("../images/3d-pic1.png"),
      url("../images/3d-pic2.png"),
      url("../images/3d-pic3.png"),
      url("../images/3d-pic4.png");
      
    background-repeat: repeat-x;
    background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
    background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
    animation: 50s para infinite linear;
  }

  @keyframes para {
    100% {
      background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 400px 0;
    }
  }

  #change-bckimages{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/office-building.jpeg") ;
   background-size: 100% 100%;
   display: flex;
   background-repeat: no-repeat;
   background-position: center center;
   background-color: transparent;
   position: relative; 
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
  
    width: 100%;  overflow: hidden; z-index: 0;
    height:100vh;
    width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

   animation: changePage5BackgroundImage 13s infinite;
  
  }

  #page5-change-background{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    
   background-size: 100% 100%;
   display: flex;
   background-repeat: no-repeat;
   background-position: center center;
   background-color: transparent;
   position: relative; 
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
  
    width: 100%;  overflow: hidden; z-index: 0;
    height:100vh;
    width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */
  
   animation-name: changePage5BackgroundImage;
   animation-duration:60s;
   animation-timing-function: ease-in-out;
   animation-iteration-count: infinite;
   animation-delay: 1s;
   transform:  perspective(1000px)  ;
  
  }
  
  
@keyframes changeBackgroundImage {
  0% {
    background: url("../images/office-building.jpeg") ;
    /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
  }
  25% {
    background-image: url('../images/login-change-bk1.jpg');
  }
  50% {
    background-image: url('../images/login-change-bk2.jpg');
  }
  75% {
    background-image: url('../images/login-change-bk3.jpg');
  }
  100% {
    background-image: url('../images/login-change-bk4.jpg');
  }
}

@keyframes para2 {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 400px 0;
  }
}
  @keyframes changeBackgroundImage2 {
    0% {
      background: url("../images/test15-rm.jpeg") ;
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    }
    25% {
      background-image: url("../images/test23-2.jpeg");
    }
    50% {
      background-image: url("../images/test26-2.jpeg");
    }
    75% {
      background-image: url("../images/test27-2.jpeg");
    }
    100% {
      background-image: url("../images/Test103-4.jpeg");
    }
  }


  @keyframes changePage5BackgroundImage {
    0% {
      background-image: url("../images/office-building.jpeg") ;
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
    }
    25% {
      background-image: url("../images/bullet-train.jpeg") ;
    
    }
    50% {
      background-image: url('../images/airplane.jpeg');
    }
    75% {
      background-image: url('../images/login-change-bk3.jpg');
    }
    100% {
      background-image: url('../images/login-change-bk4.jpg');
    }
  }
  
 /* img {
    height: 100vh;
    width: 100%;
    object-fit: contain;
    background: transparent;
  }
  */

 
  /* login index */ 
  #loginIndex1{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/test29.jpeg") ;
    background-size: 100%  100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #loginIndex2{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/test28.jpeg") ;
    background-size: 100% 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #loginIndex3{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/test30.jpeg") ;
    background-size: auto 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #loginIndex4{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/test31.jpeg") ;
    background-size: auto 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #index-next-button{
    
    animation: 50s nextbutton-float-up-1 infinite linear;
  }
  @keyframes changeBackgroundImage2 {
    0% {
      background-image: url("../images/test28.jpeg") ;
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
    }
    30% {
      background-image: url("../images/test29.jpeg") ;
    
    }
    60% {
      background-image: url('../images/test30.jpeg');
    }
    100% {
      background-image: url('../images/test31.jpeg');
    }
    
  }
  @keyframes nextbutton-float-up-1 {
    0% { transform: translateY(0vh);   }
    10% { transform: translateY(-10vh) } 
    100% { transform: translateY(-10vh) }                       
    }
    @-webkit-keyframes nextbutton-float-up-1 {
      0% { transform: translateY(0vh);   }
  
      10% { transform: translateY(-10vh) }   
      100% { transform: translateY(-10vh) }     
    }

  /* login index changes end */