#galaxyIndex1{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; 
    background: url("../images/Galaxy-3.gif") ;*/
    background: url("../images/Galaxy-3.gif") ;
    background-size: 100% 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }


  #companyglimpse{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url("../images/earth.gif") ;
    background-size: auto 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;

    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #scrollingNames{
    height: 100%;
    overflow: hidden;
    background: black;
    color: gold;
    font-family: 'BenchNine', sans-serif;
    text-transform: uppercase;
    font-size: 49px;
    letter-spacing: -.04rem;
    text-align: center;
    z-index: 99999;
  }

  #newyear2023wishes{
    background: url("../images/NewYear2023.gif") ;
    background-size: 100% 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
