.logoutlabel { 
    
    text-align: center;
    color: rgb(16, 139, 240);
    font-size: 1em;
    transition: 0.5s;
    font-family: Arial, Helvetica, sans-serif;
}
.logoutlabel2 { 
    
    text-align: center;
    color: rgb(240, 16, 16);
    font-size: 1em;
    transition: 0.5s;
    font-family: Arial, Helvetica, sans-serif;
}
.logoutlabel span { color: green; }