@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
html, body {
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100vh;
  width:100vw;
}
html, body, #app, #app>div {
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100vh;
  width:100vw;
}
html, body, #root, .App {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* css changes for  for login and singup start  bootstrap */  

 *{
  box-sizing: border-box;
}


h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex-end;
  justify-content: flex-end;
  flex-direction: center;
  text-align: left;
  
}
.example-card {
  max-width: 300px;
  margin-left: 800px;
  margin-top:0px;
} 
.auth-inner {
  
  width: 250px;
  top: 5%;
  overflow: hidden;
  margin: auto;
  position: absolute;
  background: #ffffff;
  box-shadow: 5px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 20px 55px 15px 55px;
  border-radius: 15px;
  transition: all .3s;
  z-index: 199;
  
  
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.login {

  /*background: url("../src/images/littlevisuals-high-quality-hd-banner-images-stock-photos-free-laptop-topview-download.jpg") no-repeat; 
  background: url("../src/images/test15.jpeg") no-repeat; 

  
  background-size: cover;
  min-width: 100%;
  min-height: 100%;*/
  background: url(/static/media/test15.c33cb764.jpeg) no-repeat;
  background-size: cover;
}
html, body, .login {
  height: 100vh;
}

/* css changes for login and singup end */
/*login background image css */
.bkimage {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bkimage-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
}
/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {
    .bkimage {
      background-position: relative center;
      width: 80vh;
      height: 100vh;
      
      
      
    }

}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
    .bkimage {
        background-position: center center;
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }
    
}

/* For desktop devices */
@media (min-width: 1025px) {
    .bkimage {
      background-position: center center;
      -webkit-transform: rotateY(360deg);
              transform: rotateY(360deg);
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }

  
    
}

/*login background image css */
/*3d text start */
.textblue{
              
              
  -webkit-transform: translate(0%,-0%);
              
              
          transform: translate(0%,-0%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 2em;
  font-weight: 700;
  color: red;
  
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}
.textSignIn{
  
  
  -webkit-transform: translate(0%,-0%);
  
  
          transform: translate(0%,-0%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 2em;
  font-weight: 700;
  color :#ec4949;
  white-space: nowrap;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}

.textLoggedinAs{

  -webkit-transform: translate(10%,-10%);

          transform: translate(10%,-10%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 2em;
  font-weight: 700;
  color :#500707;
  z-index: 99;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
  
}

.textcopyright{

  -webkit-transform: translate(0%,-0%);

          transform: translate(0%,-0%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 1em;
  font-weight: 700;
  color :#500707;
  z-index: 99;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
 
}

.textbrand{

  -webkit-transform: translate(10%,-10%);

          transform: translate(10%,-10%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 1.5em;
  font-weight: 700;
  color :#a7f152;
  
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
  
}



/*3d text end */
.userdtlsnavbar{
  color :#fcf5f5;
}

.cursor {
  cursor: pointer;
}

#offset {
  -webkit-animation: letter-flicker 2s linear infinite;
          animation: letter-flicker 2s linear infinite;
}
@-webkit-keyframes letter-flicker {
  0% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  2% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  4% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  
  19% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  21% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  23% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  80% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  83% {
    opacity:0.4;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  87% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
}
@keyframes letter-flicker {
  0% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  2% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  4% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  
  19% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  21% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  23% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  80% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  83% {
    opacity:0.4;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  87% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
}

#offsetbrand {
  -webkit-animation: letter-flicker 2s ease-in-out forwards infinite;
          animation: letter-flicker 2s ease-in-out forwards infinite;
}
@keyframes letter-flicker {
  0% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  2% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  4% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  
  
  19% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  21% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  23% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  
  80% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  83% {
    opacity:0.4;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  87% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
}
/*silk slider */
.silkcontainer {
  padding: 40px;
  background: #419be0;
}

.slick-slide img {
  margin: auto;
}

.loginsilk-img{
  position:absolute;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
    margin: auto; 
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: red;
  z-index: 21;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: red;
  z-index: 21;
}
.carousel-image-style{
    position:absolute;
      top:0;
      bottom:0;
      right: 0;
      left: 0;
      margin: auto; 
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
}



/*.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}*/

.img:hover {
    -webkit-transform:scale(1.2); 
            transform:scale(1.2);
  }
  .img { 
    transition: all 0.7s ease;
  }

  /* Position the "next1 button" to the right */
.next1 {
    right: 0;
    border-radius: 3px 0 0 3px;
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
  }
  /*carousel css start */
/* Next & previous buttons */
.prev1,
.next1 {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  -webkit-transform: rotateZ(360deg);
          transform: rotateZ(360deg);
}

/* Position the "next1 button" to the right */
.next1 {
  right: 0;
  border-radius: 3px 0 0 3px;
  -webkit-transform: rotateZ(360deg);
          transform: rotateZ(360deg);
}

/* On hover, add a black background color with a little bit see-through */
.prev1:hover,
.next1:hover {
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transform: rotateZ(360deg);
          transform: rotateZ(360deg);
}
/*carousel css end */



/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {
    
}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
    .bkimage {
        background-position: center center;
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }
  /*  .carousel-item img{
        transform: rotateZ(360deg);
        object-fit: cover;
        -moz-animation: spinHorizontalHomesceen 5s 2 linear;
        -o-animation: spinHorizontalHomesceen 5s 2 linear;    
        -webkit-animation: spinHorizontalHomesceen 5s 2 linear;
        animation: spinHorizontalHomesceen 5s 2 linear;
    }*/
}

/* For desktop devices */
@media (min-width: 1025px) {
  
   /* .carousel-item img{      
      width:100vw;
      margin: 0;      
      transform: rotateZ(360deg);
      object-fit: cover;
      -moz-animation: spinHorizontalHomesceen 5s 2 linear;
      -o-animation: spinHorizontalHomesceen 5s 2 linear;    
      -webkit-animation: spinHorizontalHomesceen 5s 2 linear;
      animation: spinHorizontalHomesceen 5s 2 linear;
   
      max-width: 100%;
    }*/

    
}
.carousel-inner > .item > img {
  margin: 0 auto;
}
.carousel-inner{
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;

  
  overflow: hidden;
  }
.carousel-item{

  width: 100vw;
  

  
  /*-moz-animation: spinHorizontalHomesceen 5s 2 linear;
  -o-animation: spinHorizontalHomesceen 5s 2 linear;    
  -webkit-animation: spinHorizontalHomesceen 5s 2 linear;
  animation: spinHorizontalHomesceen 5s 2 linear;
 
  -webkit-transform-origin:50% 50%;
  -moz-transform-origin:50% 50%;
  -ms-transform-origin:50% 50%;
  -o-transform-origin:50% 50%;
  transform-origin:50% 50%;
  -webkit-animation:scaleLoginImage 35s linear 0s infinite;
  animation:scaleLoginImage 35s linear 0s infinite;
  transition:all 1s ease-in-out;
  -webkit-transition:all 1s ease-in-out;
  -moz-transition:all 1s ease-in-out;
  -ms-transition:all 1s ease-in-out;
  -o-transition:all 1s ease-in-out;
  transition:all 1s ease-in-out;
 */
}
.carousel-item img{

  margin-left: 5rem;
 
  z-index: 99;
  height: 100%;
    /* max-height: 600px; */
    width: 1000px;
    height:100vh;
    width: 100vw;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  
    background-color: hsla(200, 40%, 30%, 0.4);      
    background-repeat: repeat-x;
    background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
    background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
  
}


@-webkit-keyframes spinHorizontalHomesceen {
    0% { -webkit-transform: rotateY(0deg); transform: rotateY(0deg); }
    100% { -webkit-transform: rotateY(360deg); transform: rotateY(360deg); }
    
}


@keyframes spinHorizontalHomesceen {
    0% { -webkit-transform: rotateY(0deg); transform: rotateY(0deg); }
    100% { -webkit-transform: rotateY(360deg); transform: rotateY(360deg); }
    
}
@-webkit-keyframes kenburns
{
  0%
  {
    -webkit-transform:scale(1);
            transform:scale(1);
    transition:-webkit-transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s, -webkit-transform 35000ms linear 0s
  }
  100%
  {
    -webkit-transform:scale(1.3);
            transform:scale(1.3);
    transition:-webkit-transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s, -webkit-transform 35000ms linear 0s
  }

}
@keyframes kenburns
{
  0%
  {
    -webkit-transform:scale(1);
            transform:scale(1);
    transition:-webkit-transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s, -webkit-transform 35000ms linear 0s
  }
  100%
  {
    -webkit-transform:scale(1.3);
            transform:scale(1.3);
    transition:-webkit-transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s, -webkit-transform 35000ms linear 0s
  }

}

.welcometext{
  text-shadow: 2px 2px 5px red;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
}
.welcomeusertxt {
  text-align: right;
  font-size: 20px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
  font-weight:bold;
}

.logincontainer {
  /*display: grid;*/
  width: 100vw;
  height: 100vh;
 /* grid-template-columns: 1fr;*/
  -webkit-transform: perspective(1000px) rotateZ(360deg);
  transform: perspective(1000px) rotateZ(360deg);
}

.loginimageclass2{
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-transform: rotateZ(360deg);
          transform: rotateZ(360deg);
  object-fit: contain;
}

.imageclass{
  background-size: auto 100%;
  position: absolute;
  height: 10vh;
  width: 10vw;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
  
  button {
    border: none;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.75em;
    display: inline-block;
    text-decoration: none;
  }
  button:hover {
    cursor: pointer;
  }
  button:focus {
    text-decoration: none;
    outline: none;
  }
  
  .comment-box {
    max-width: 37.5rem;
   
    background-color: #909090;
    border-radius: 0.875rem;
    padding: 0.2rem 1rem 2rem;
  }
  
  .comment-form {
    background-color: #fff;
    border-radius: 0.25rem;
    margin-bottom: 2rem;
    padding: 1rem;
  }
  
  .comment-form-fields {
    margin-bottom: 0.25rem;
  }
  .comment-form-fields input, .comment-form-fields textarea {
    border: none;
    border-bottom: 1px solid #404040;
    font-size: 0.85rem;
    padding: 0.25rem 0;
    width: 99%;
  }
  .comment-form-fields input:focus, .comment-form-fields textarea:focus {
    border-bottom-color: #00BDFC;
    outline: none;
  }
  .comment-form-fields textarea {
    font-style: italic;
  }
  
  .comment-count {
    color: #404040;
  }
  
  #comment-reveal {
    float: right;
    background-color: #00BDFC;
    color: #fff;
  }
  
  .comment {
    border-top: 0.125rem solid #404040;
  }
  
  .comment-body {
    font-style: italic;
    margin-left: 1rem;
  }
  
  .comment-footer {
    margin-bottom: 1rem;
  }
  
  .comment-footer-delete {
    padding: 0.2rem 0;
    color: #404040;
    text-decoration: none;
  }
  .comment-footer-delete:hover {
    color: #00BDFC;
    font-weight: bold;
  }

.pane-center{
  z-index:inherit !important;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.cursor {
  cursor: pointer;
}



/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {
      .modal {
        text-align: center;
        position: absolute;
          top: 15%;
          left: 50%;
          width: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        -webkit-transform:  perspective(1000px) rotateY(45deg);
                transform:  perspective(1000px) rotateY(45deg);
        z-index: 10000000 !important;;
      }
      .modal.fade {
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        -webkit-transform:  perspective(1000px) rotateY(45deg);
                transform:  perspective(1000px) rotateY(45deg);
        z-index: 10000000 !important;
        left: 50%;
        width: 100%;
        overflow: hidden;
        background: transparent;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      }
      
    .modal-dialog {
      width: 0px;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 20px;
      right: 0;
      height: 100vh;
      display: flex;
      max-width: 100%;
      max-height: 100%;
    }
}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
      .modal {
        text-align: center;
        position: absolute;
          top: 10%;
          left: 50%;
          width: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        -webkit-transform:  perspective(1000px) rotateY(45deg);
                transform:  perspective(1000px) rotateY(45deg);
        z-index: 10000000 !important;;
      }
      .modal.fade {
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        -webkit-transform:  perspective(1000px) rotateY(45deg);
                transform:  perspective(1000px) rotateY(45deg);
        z-index: 10000000 !important;
        left: 50%;
        width: 100%;
        overflow: hidden;
        background: transparent;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      }
      
    .modal-dialog {
      width: 0px;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 20px;
      right: 0;
      height: 100vh;
      display: flex;
      max-width: 100%;
      max-height: 100%;
    }
    .cursor {
      cursor: default;
    }
}
/* For desktop devices */
@media (min-width: 1025px) {
      modal {
        text-align: center;
        position: absolute;
          top: 10%;
          left: 50%;
          width: 30vw;
        -webkit-transform: translate(-10%, -50%);
                transform: translate(-10%, -50%);
        -webkit-transform:  perspective(1000px) rotatez(-10deg);
                transform:  perspective(1000px) rotatez(-10deg);
        z-index: 10000000 !important;;
      }
      .modal.fade {
        -webkit-transform: translate(-10%, -50%);
                transform: translate(-10%, -50%);
        -webkit-transform:  perspective(1000px) rotatez(-10deg);
                transform:  perspective(1000px) rotatez(-10deg);
        z-index: 10000000 !important;
        left: 50%;
        width: 30vw;
        overflow: hidden;
        background: transparent;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      }
      
    .modal-dialog {
      width: 0px;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 20px;
      right: 0;
      height: 100vh;
      display: flex;
      max-width: 100%;
      max-height: 100%;
      }
    }

#root {
    min-height: 100vh;
}
body > #root > div {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
   }
   body{
     height: 100%;
     width: 100%;
   }

   /* @import url(https://unpkg.com/bootstrap@4.1.0/dist/css/bootstrap.min.css) */

/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {

}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
}


/*social followup star */
.social-container {
  position: relative;
    background: white;
    padding: 0px 0px;
    z-index: 10;
    margin-left: 10vw;
  }
  a.social {
    margin: 0 1rem;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    display: inline-block;
  }
  a.social:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }
  /*social followup end */

body, html {
  height: 100%;
}
body > #root > div {
 max-width: 100%;
 max-height: 100%;
 height: 100%;
 width: 100%;
}
body{
  height: 100%;
  width: 100%;
}
html, body, #root, .Login {
  height: 100%;
}
.LoginMenuLinksText{
  text-transform: uppercase;
  font: 700 22px/1 "Arvo", sans-serif;
  text-align: center;
}
.LoginMenutextbrand{

  -webkit-transform: translate(10%,-10%);

          transform: translate(10%,-10%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 0.8em;
  font-weight: 100;
  color :#a600ff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-sizing: border-box;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
  -webkit-animation: shine01 4s linear infinite;
          animation: shine01 4s linear infinite;
 
  
}

#offset {
  -webkit-animation: letter-flicker 2s linear infinite;
          animation: letter-flicker 2s linear infinite;
}
@-webkit-keyframes letter-flicker {
  0% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  2% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  4% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  
  19% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  21% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  23% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  80% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  83% {
    opacity:0.4;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  87% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
}
@keyframes letter-flicker {
  0% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  2% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  4% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  
  19% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  21% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  23% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  80% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  83% {
    opacity:0.4;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  87% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
}



/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {
    .bkimage {
        background-position: relative center;
        width: 100vh;
        height: 100vh;
        padding-left: 2.9%;  
        padding-bottom: 2.9%;  
        background-size: cover;       
        -webkit-transform: rotateZ(360deg);       
                transform: rotateZ(360deg);
        object-fit: cover;

    }
    .img-responsive{
        display: block;
        max-width: 100%;
        height: auto;
        }
        .login-hide-show{
          text-align: center;
          position: absolute;
          top: 20%;
          left: 60%;
          transform: translate(-50%, -50%);
          -webkit-transform:translate(-50%, -50%);
          color: white;
       
          text-transform: uppercase;
          font-family: verdana;
          font-size: 3em;
          font-weight: 1000;
          size: 500px;
          z-index: 99;
      
          transform:  perspective(1000px)  rotateZ(10deg) ;
          -webkit-transform: perspective(1000px)  rotateZ(10deg) ;
        }
        .hero-text {
          text-align: center;
          position: absolute;
          top: 10%;
          left: 20%;
          font-size: 0.5em;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          color: white;
          z-index: 199;
          transform:  perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
          -webkit-transform:perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
        }

        #hero-1:before {
          background-image: url(/static/media/test14.1b016cae.jpeg);
          transform: translateZ(-1px) scale(2);
          -webkit-transform:translateZ(-1px) scale(2);
          z-index: 25;
        }
        
        #hero-2:before {
          background-image: url(/static/media/test11.be4c3fed.jpeg);
          transform: translateZ(-1px) scale(2);
          -webkit-transform:translateZ(-1px) scale(2);
          z-index: 25;
        }
        
        #hero-3:before {
          background-image: url(/static/media/test1.597e505d.jpeg);
          transform: translateZ(-1px) scale(2);
          -webkit-transform:translateZ(-1px) scale(2);
          z-index: 25;
        }
        
        #hero-4:before {
          background-image: url(/static/media/test9.cd0b31a6.jpeg);
          transform: translateZ(-1px) scale(2);
          -webkit-transform:translateZ(-1px) scale(2);
          z-index: 25;
        }
      
        .hero-imageposter {
          height: 70vh;
          width: 100vw;
         }
         #hero-8{
          /* margin-left: 5vw; */
        }
}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
    .bkimage {
      width: 100vw;
      height: 100vh;
        background-position: center center;
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
        object-fit: cover;
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }
    .login-hide-show{
      text-align: center;
      position: absolute;
      top: 20%;
      left: 60%;
      transform: translate(-50%, -50%);
      -webkit-transform:translate(-50%, -50%);
      color: white;
   
      text-transform: uppercase;
      font-family: verdana;
      font-size: 3em;
      font-weight: 1000;
      size: 500px;
      z-index: 99;
  
      transform:  perspective(1000px) rotateY(1deg) rotateZ(10deg) rotateX(1deg);
      -webkit-transform:perspective(1000px) rotateY(1deg) rotateZ(10deg) rotateX(1deg);
    }
    .hero-text {
      text-align: center;
      position: absolute;
      top: 10%;
      left: 20%;
      font-size: 1em;
      transform: translate(-50%, -50%);
      -webkit-transform:translate(-50%, -50%);
      color: white;
      z-index: 199;
      transform:  perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
      -webkit-transform:perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
    }

    #hero-1:before {
      background-image: url(/static/media/test14.1b016cae.jpeg);
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: 25;
    }
    
    #hero-2:before {
      background-image: url(/static/media/test11.be4c3fed.jpeg);
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: 25;
    }
    
    #hero-3:before {
      background-image: url(/static/media/test1.597e505d.jpeg);
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: 25;
    }
    
    #hero-4:before {
      background-image: url(/static/media/test9.cd0b31a6.jpeg);
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: 25;
    }
    
    .normal-scroll {
      background: #fff;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
    }

    .hero-imageposter {
      height: 200vh;
    
     }
    
     #hero-8{
      /* margin-left: 10vw; */
    }
}

/* For desktop devices */
@media (min-width: 1025px) {
  /*  .bkimage {
      background-position: center center;
      transform: rotateZ(360deg);
      object-fit: cover;
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }*/
    .bkimage {
    width: 100vw;
    height: 100vh;
      background-position: center center;
      -webkit-transform: rotateZ(360deg);
              transform: rotateZ(360deg);
      object-fit: cover;
      
    }

    .login-hide-show{
      text-align: center;
      position: absolute ;
      top: 40%;
      left: 40%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      color: white;
   
      text-transform: uppercase;
      font-family: verdana;
      font-size: 3em;
      font-weight: 1000;
      size: 500px;
      z-index: 99;
  
      transform:  perspective(1000px) rotateY(1deg) rotateZ(10deg) rotateX(1deg);
      -webkit-transform:perspective(1000px) rotateY(1deg) rotateZ(10deg) rotateX(1deg);
    }
    .hero-text {
      text-align: center;
      position: absolute;
      top: 10%;
      left: 20%;
      font-size: 2em;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      color: white;
      z-index: 199;
      transform:  perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
      -webkit-transform: perspective(1000px) rotateY(0deg) rotateZ(1deg) rotateX(0deg);
    }


    #hero-1:before {
      background-image: url(/static/media/test14.1b016cae.jpeg);
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: -1;
    }
    
    #hero-2:before {
      background-image: url(/static/media/test11.be4c3fed.jpeg);
      transform: translateZ(-1px) scale(2);
      -webkit-transform:translateZ(-1px) scale(2);
      z-index: -2;
    }
    
    #hero-3:before {
      background-image: url(/static/media/test1.597e505d.jpeg);
      background-attachment: fixed;
      z-index: -3;
    }
    
    #hero-4:before {
      background-image: url(/static/media/test9.cd0b31a6.jpeg);
      background-attachment: fixed;
      z-index: -4;
    }

    .normal-scroll {
      background: #fff;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
    }
    
    .parallexcontainer {
      
      color: #ccc;
      font-size: 4vw;
      margin: 0 auto;
      padding: 25vh 0;
      text-align: center;
      width: 100%;
      

    }

    .hero-imageposter {
      height: 200vh;
    
     }
     #hero-8{
      /* margin-left: 20vw; */
    }
}
@-webkit-keyframes spinHorizontal {
    0% { -webkit-transform: rotateZ(0deg); transform: rotateZ(0deg); }
    100% { -webkit-transform: rotateZ(360deg); transform: rotateZ(360deg); }
    
}
@keyframes spinHorizontal {
    0% { -webkit-transform: rotateZ(0deg); transform: rotateZ(0deg); }
    100% { -webkit-transform: rotateZ(360deg); transform: rotateZ(360deg); }
    
}

@-webkit-keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

  .bkimage {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/test16.bf2d6177.jpeg);
    background-origin: border-box;
    border-left: 5px solid rgba(0, 0, 0, 0.5);

    /*-moz-animation: spinHorizontal 20s 5 linear;
    -o-animation: spinHorizontal 20s 5 linear;    
    -webkit-animation: spinHorizontal 20s 5 linear;
    animation: spinHorizontal 20s 5 linear;*/

    
    -webkit-transform: perspective(1000px) rotateZ(360deg);
    transform: perspective(1000px) rotateZ(360deg);
 
  
   
    background-origin: border-box;
    border-left: 5px solid rgba(0, 0, 0, 0.5);

  
    
  }

  
  .logincontainer {
  /*display: grid;*/
  width: 100vw;
  height: 100vh;
  max-width: 100%;
 /* grid-template-columns: 1fr;*/

}

.div-block-87 img {
  max-width: 100%;
  height: auto;
  
}
.loginimageclass{
  position: absolute;
  height: 80%;
  width: 80%;
  -webkit-transform:  rotateZ(360deg);
  transform:  rotateZ(360deg);
  object-fit: contain;
  margin-left: 5rem;
  -webkit-transform-origin:50% 50%;
  transform-origin:50% 50%;
 /* -webkit-animation:scaleLoginImage 30s  linear 0s infinite;
  animation:scaleLoginImage 30s linear 0s infinite;
  
  transition:all 1s ease-in-out;
  -webkit-transition:all 1s ease-in-out;
  -moz-transition:all 1s ease-in-out;
  -ms-transition:all 1s ease-in-out;
  -o-transition:all 1s ease-in-out;
  transition:all 1s ease-in-out;
*/
}
@-webkit-keyframes loginkenburns
{
  0%
  {
    -webkit-transform:scale(1);
            transform:scale(1);
    transition:-webkit-transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s, -webkit-transform 35000ms linear 0s
  }
  100%
  {
    -webkit-transform:scale(1.3);
            transform:scale(1.3);
    transition:-webkit-transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s, -webkit-transform 35000ms linear 0s
  }

}
@keyframes loginkenburns
{
  0%
  {
    -webkit-transform:scale(1);
            transform:scale(1);
    transition:-webkit-transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s, -webkit-transform 35000ms linear 0s
  }
  100%
  {
    -webkit-transform:scale(1.3);
            transform:scale(1.3);
    transition:-webkit-transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s;
    transition:transform 35000ms linear 0s, -webkit-transform 35000ms linear 0s
  }

}

@-webkit-keyframes scaleLoginImage {
      From {
        -webkit-transform: scale(0.2);
        transform:scale(0.2);
        transition:-webkit-transform 1s linear 0s;
        transition:transform 1s linear 0s;
        transition:transform 1s linear 0s, -webkit-transform 1s linear 0s;
    }
    To {
        -webkit-transform: scale(2.0);
        transform:scale(2.0);
        transition:-webkit-transform 1s linear 0s;
        transition:transform 1s linear 0s;
        transition:transform 1s linear 0s, -webkit-transform 1s linear 0s;
    }
}

@keyframes scaleLoginImage {
      From {
        -webkit-transform: scale(0.2);
        transform:scale(0.2);
        transition:-webkit-transform 1s linear 0s;
        transition:transform 1s linear 0s;
        transition:transform 1s linear 0s, -webkit-transform 1s linear 0s;
    }
    To {
        -webkit-transform: scale(2.0);
        transform:scale(2.0);
        transition:-webkit-transform 1s linear 0s;
        transition:transform 1s linear 0s;
        transition:transform 1s linear 0s, -webkit-transform 1s linear 0s;
    }
}

/*firework start */

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 30%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  z-index: 100;
}

.pyro > .after {
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -202px -87.6666666667px #00ff4d, 248px 65.3333333333px #0051ff, 216px -344.6666666667px #ff0059, -85px 29.3333333333px #1e00ff, -178px -315.6666666667px #3700ff, -116px -25.6666666667px #66ff00, 149px -112.6666666667px #09ff00, -170px -240.6666666667px #00fff2, -67px -349.6666666667px #f700ff, -115px -30.6666666667px #3700ff, -162px -334.6666666667px #00ff15, -31px 75.3333333333px #a600ff, -120px -279.6666666667px #59ff00, 249px -223.6666666667px #ff5100, -5px -227.6666666667px #ff00bf, -183px -71.6666666667px #ff009d, 189px -114.6666666667px #00ffbb, -57px -341.6666666667px #e600ff, 3px -88.6666666667px #ff00d0, 192px -352.6666666667px #59ff00, 34px -360.6666666667px #ff002b, 105px -12.6666666667px #d0ff00, -73px -37.6666666667px #bfff00, 28px -243.6666666667px #1a00ff, 15px -256.6666666667px #ff0080, -55px -97.6666666667px #00ff99, 25px -406.6666666667px #dd00ff, -196px 79.3333333333px #00ff5e, -28px -312.6666666667px #2b00ff, -146px 70.3333333333px #00ff99, 110px -234.6666666667px #ff3300, 161px 40.3333333333px #00ff55, 14px -0.6666666667px #ff002f, -211px -53.6666666667px red, 170px 6.3333333333px #0900ff, 176px -201.6666666667px #2600ff, 84px -331.6666666667px #0022ff, 68px -154.6666666667px #008cff, -133px 62.3333333333px #00ff1e, 2px 43.3333333333px #00f2ff, 29px 65.3333333333px #0062ff, -229px 9.3333333333px #ff6600, -202px -390.6666666667px #00ffd9, 237px -264.6666666667px #a2ff00, 101px -78.6666666667px #88ff00, 243px -355.6666666667px #0077ff, 166px 50.3333333333px #00ffaa, 185px -354.6666666667px #00ffdd, -236px -116.6666666667px #ff005e, -12px -77.6666666667px #ff4000, 243px -148.6666666667px #ffa600;
  }
}
@keyframes bang {
  to {
    box-shadow: -202px -87.6666666667px #00ff4d, 248px 65.3333333333px #0051ff, 216px -344.6666666667px #ff0059, -85px 29.3333333333px #1e00ff, -178px -315.6666666667px #3700ff, -116px -25.6666666667px #66ff00, 149px -112.6666666667px #09ff00, -170px -240.6666666667px #00fff2, -67px -349.6666666667px #f700ff, -115px -30.6666666667px #3700ff, -162px -334.6666666667px #00ff15, -31px 75.3333333333px #a600ff, -120px -279.6666666667px #59ff00, 249px -223.6666666667px #ff5100, -5px -227.6666666667px #ff00bf, -183px -71.6666666667px #ff009d, 189px -114.6666666667px #00ffbb, -57px -341.6666666667px #e600ff, 3px -88.6666666667px #ff00d0, 192px -352.6666666667px #59ff00, 34px -360.6666666667px #ff002b, 105px -12.6666666667px #d0ff00, -73px -37.6666666667px #bfff00, 28px -243.6666666667px #1a00ff, 15px -256.6666666667px #ff0080, -55px -97.6666666667px #00ff99, 25px -406.6666666667px #dd00ff, -196px 79.3333333333px #00ff5e, -28px -312.6666666667px #2b00ff, -146px 70.3333333333px #00ff99, 110px -234.6666666667px #ff3300, 161px 40.3333333333px #00ff55, 14px -0.6666666667px #ff002f, -211px -53.6666666667px red, 170px 6.3333333333px #0900ff, 176px -201.6666666667px #2600ff, 84px -331.6666666667px #0022ff, 68px -154.6666666667px #008cff, -133px 62.3333333333px #00ff1e, 2px 43.3333333333px #00f2ff, 29px 65.3333333333px #0062ff, -229px 9.3333333333px #ff6600, -202px -390.6666666667px #00ffd9, 237px -264.6666666667px #a2ff00, 101px -78.6666666667px #88ff00, 243px -355.6666666667px #0077ff, 166px 50.3333333333px #00ffaa, 185px -354.6666666667px #00ffdd, -236px -116.6666666667px #ff005e, -12px -77.6666666667px #ff4000, 243px -148.6666666667px #ffa600;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(250px);
    -moz-transform: translateY(250px);
    -webkit-transform: translateY(250px);
    -o-transform: translateY(250px);
    -ms-transform: translateY(250px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 20%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 20%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 20%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 20%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

/*fireworkk end */
/*parallex effect start */

.normalsection {
  padding: 25vh 10%;
  position: relative;
  min-height: 100vh;
  width: 100vw;
  -webkit-transform-style: inherit;
          transform-style: inherit;
  /*-moz-animation: shake 35000ms 1 linear;
    -o-animation: shake 35000ms 1 linear;    
    -webkit-animation: shake 35000ms 1 linear;
    animation: shake 35000ms 1 linear;
    */
}

.normalsection, .normalsection:before {
  background: 50% 50% / cover;
  box-sizing: border-box;
}

.normalsection:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}



.shakingsection {
 
  position: relative;
  min-height: 100vh;
  width: 100vw;
  -webkit-transform-style: inherit;
          transform-style: inherit;
 
    
}

.shakingsection, .shakingsection:before {
  background: 50% 50% / cover;
  background-origin: border-box;
  

}

.shakingsection:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
}

/* The brightest duotone color */
.darken::before {
  background-color: rgba(221, 204, 0, 1);
}

/* The darkest duotone color */
.lighten::after {
  background-color: rgba(221, 0, 0, 1);
}



@media screen and (-webkit-min-device-pixel-ratio: 2) {
    #section-5:before {
        background-attachment: scroll;
    }
}



/*carousel  section images start*/

#carousel-hero-5:before {
  background-image: url(/static/media/test23.6d596d3f.jpeg);
  transform: translateZ(-1px) scale(1);
  -webkit-transform:translateZ(-1px) scale(1);
  z-index: -1;
  

  

}





#hero-8{
  position: relative;
  min-height: 80%;
  width: 100%;
  -webkit-transform-style: inherit;
          transform-style: inherit;

  
 
  /* -moz-animation: spinning-poster2  20s 1s linear infinite;
  -o-animation: spinning-poster2  20s 1s linear infinite;    
  -webkit-animation: spinning-poster2  20s 1s linear infinite;
  animation: spinning-poster2  20s 1s linear infinite;
  -webkit-animation-delay: 10s;
  animation-delay: 10s;  */
  -webkit-transform-origin:  30% 55%;
  transform-origin: 30% 55%;
  -webkit-transform: scale(1.4) perspective(1000px) rotateZ(0deg) rotateY(0deg) rotateX(-20deg);
  transform:  scale(1.4) perspective(1000px) rotateZ(0deg) rotateY(0deg) rotateX(-20deg);
  
}
#hero-8:before,
#hero-8:after {
  content: "";
  position: relative;
  top: 0;
  width: 0;
  min-height: 100%;
  box-sizing: border-box;
  background: transparent;
  border-top: 4px solid white;
  border-bottom: 4px solid white;

  border-right: 6px solid rgba(0, 0, 0, 0.5);
  
}
#hero-8:before {
  left: calc(10% - 7px);
}
#hero-8:after {
  right: calc(55% - 7px);

}

.alignleftclass{
 
}

#carousel-hero-1:before {
  background-image: url(/static/media/test16.bf2d6177.jpeg);
  transform: translateZ(-1px) scale(1);
  -webkit-transform:translateZ(-1px) scale(1);
  z-index: -1;
  box-sizing: border-box;
  /*left:15vw ;*/
 
  -webkit-transform-style: preserve-3d;
 
          transform-style: preserve-3d;
  /* -webkit-transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
  transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg); */    
  -webkit-animation: spinning-circle2  10s 2 linear;
  animation: spinning-circle2  10s 2 linear;

}

#carousel-hero-2:before {
  background-image: url(/static/media/test9.cd0b31a6.jpeg);
  transform: translateZ(-1px) scale(1);
  -webkit-transform:translateZ(-1px) scale(1);

  box-sizing: border-box;
  /*left:15vw ;*/
  z-index: -2;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  /* -webkit-transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
  transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg); */    
  -webkit-animation: spinning-circle2  10s 2 linear;
  animation: spinning-circle2  10s 2 linear;
}

#carousel-hero-3:before {
  background-image: url(/static/media/test1.597e505d.jpeg);
  transform: translateZ(-1px) scale(1.5);
  -webkit-transform:translateZ(-1px) scale(1.5);
 

  box-sizing: border-box;
  /*left:15vw ;*/
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  /* -webkit-transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
  transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg); */
  z-index: -3;    
  -webkit-animation: spinning-circle2  10s 2 linear;
  animation: spinning-circle2  10s 2 linear;
}

#carousel-hero-4:before {
  background-image: url(/static/media/test.5ca44966.jpeg);
  transform: translateZ(-1px) scale(2);
  -webkit-transform:translateZ(-1px) scale(2);
  margin-left: 20rem;
  z-index: -4;

  
  box-sizing: border-box;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
  transform:  perspective(1000px) rotateY(-5deg) rotateX(0deg) rotateZ(-5deg);
}

.login4thsection {
 
  position: relative;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  -webkit-transform-style: inherit;
          transform-style: inherit;
 
    
}

.login4thsection, .login4thsection:before {
  background: 50% 50% / cover;
  background-origin: border-box;
  

}

.login4thsection:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
}
/*carousel section images end*/
.carousel-control-next-icon:after
{
  content: '>';
  font-size: 1px;
  color: #961ec5;
  z-index: 21;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 1px;
  color: #961ec5;
  z-index: 21;
}

/*section spaer */
.spcerclas {
 
  position: relative;


}
/*test section */
#hero-test:before {
  background-image: url(/static/media/prallax-test.71634ceb.jpeg);
  box-sizing: border-box;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  
}


/* The hero image */
.hero-imageposter {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image:  url(/static/media/test23.6d596d3f.jpeg);

  /* Set a specific height */
  
  
  max-width: 100%;
  
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;

  -webkit-transform-style: preserve-3d;

          transform-style: preserve-3d;
  -webkit-transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) translateZ(-1px) scale(1);
  transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) translateZ(-1px) scale(1);
}



/* Place text in the middle of the image */
.hero-imagepostertext {
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  
}

/*moving text  start*/
.loginMoving-text {
 
  
  position: relative;

  text-transform: uppercase;
  font: 700 20px/1 "Arvo", sans-serif;
  text-align: left;
  margin-left: 10rem;
  top: 5rem;
 
  -webkit-animation: in 15s ease-in-out forwards infinite;
          animation: in 15s ease-in-out  forwards infinite;
  -webkit-animation-delay: 20s;
          animation-delay: 20s;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
  -webkit-transform:  perspective(1000px)  rotateZ(1deg);
  transform:  perspective(1000px)  rotateZ(1deg) ;
  max-width: 100vw;
  z-index: 12;

}


.loginMoving-quotes-text{
 
  
 

  text-transform: uppercase;
  font: 700 40px/1 "Arvo", sans-serif;
  text-align: center;
  margin-left: 5rem;
  
 
  /*-webkit-animation: quoteEffect 15s ease-in-out forwards infinite;
          animation: quoteEffect 15s ease-in-out  forwards infinite;
  -webkit-animation-delay: 20s;
          animation-delay: 20s;*/
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);

  max-width: 100vw;
  z-index: 12;
  
  transform: translate(0, -50%) scale(1, 1.3);
  -webkit-transform:  perspective(1000px) rotateZ(-5deg) ;
  transform:  perspective(1000px) rotateZ(-5deg) ;
  
}

@-webkit-keyframes in {
  0% {
    letter-spacing: 10px;
  }
  100% {
    letter-spacing: 5px;
  }
}

@keyframes in {
  0% {
    letter-spacing: 10px;
  }
  100% {
    letter-spacing: 5px;
  }
}


@-webkit-keyframes quoteEffect {
  0% {
    letter-spacing: 20px;
  }
  100% {
    letter-spacing: 5px;
  }
}

@keyframes quoteEffect {
  0% {
    letter-spacing: 20px;
  }
  100% {
    letter-spacing: 5px;
  }
}
/*moving text end*/
/*parallex effect end */
/*book section start */
#booksection{
  

  -webkit-transform: translate(-50%, -50%);
  

          transform: translate(-50%, -50%);
  box-sizing: border-box;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: sticky;
  position: -webkit-sticky;
  scroll-behavior: none;
  
  -webkit-transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(1deg);
  transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(1deg);
  z-index: 20;
}
/*book section end*/

.poster3d{

  position: relative;
  font-family: Arial;
  margin-right: 30vw;
  margin-top: 11vw;
  margin-left: 20vw;
  -webkit-transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(1deg);
  transform:  perspective(1000px) rotateY(30deg) rotateX(0deg) rotateZ(0deg);
}
.cutecontainer{

  position: relative;
  font-family: Arial;

}

.cutecontainer-text-block {
  
  text-transform: uppercase;
  font: 700 24px/1 "Arvo", sans-serif;
  position: absolute;
bottom: -100px;
text-align: center;
  background-color: #00f2ff;
  color: white;
  padding-left: 1px;
  padding-right: 20px;
  width: 40%;
  height: 20%;
  
  box-sizing: border-box;

}



.hollywoodtext {
  text-align: center;
  color: #0022ff;
  font-family: "Permanent Marker", cursive;
  font-size: 20px;
}

.cutecontainer-text-block-right {
  
  text-transform: uppercase;
  font: 700 24px/1 "Arvo", sans-serif;
  position: absolute;
bottom: 100px;
text-align: center;
  background-color: white ;
  color: transparent;
  padding-left: 1px;
  padding-right: 20px;
  width: 20%;
  height: 10%;
  right: 40px;
  box-sizing: border-box;

  -webkit-transform-origin: 80% 15%;;
  transform-origin: 50% 5%;;
  -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-55deg) rotateX(10deg);

}
.spinbrand{    
  -webkit-animation: spinning-circle3  10s 5s linear infinite;
  animation: spinning-circle3 10s 5s linear infinite;
}

.spinbrand::before{

  content:''; position:absolute; 
  height:100%; width:100%; 
  animation:rotate 3s linear reverse infinite;
}

.collapsed{    
  -webkit-animation: move-1  45s 1s  ease-in-out infinite;
  animation: move-1 45s 1s  ease-in-out infinite;
  z-index: 500;
}
@keyframes rotate { 100% { -webkit-transform:rotate(-360deg); transform:rotate(-360deg); } }

@media (min-width: 768px) {
  .hollywoodtext {
    font-size: 2vw;
  }
}


@-webkit-keyframes spinning-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 5% 5%;
    transform-origin: 5% 5%;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 5% 5%;
    transform-origin: 5% 5%;


  }
}


@keyframes spinning-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 5% 5%;
    transform-origin: 5% 5%;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 5% 5%;
    transform-origin: 5% 5%;


  }
}


@keyframes spinning-circle2 {
  0% {

    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    z-index: 99;
  }
  20% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
  30% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
  100% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
}

@-webkit-keyframes spinning-circle2 {
  0% {

    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    z-index: 99;
  }
  20% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
  30% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
  100% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  }
}

@keyframes spinning-circle3 {
  from {-webkit-transform:rotate(0deg);transform:rotate(0deg);}
  to {-webkit-transform:rotate(360deg);transform:rotate(360deg);}
  
}

@-webkit-keyframes spinning-circle3 {
  from {
    -webkit-transform:rotate(0deg);
  }

  to {
    -webkit-transform:rotate(360deg);
  }
}
 /*sprin circle end */
 /*brand flip image start*/

 #walkinglioncircle {
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/Lion-walk.29d08b1c.gif);
 background-size: 80% 40%;
 margin-left: 10px;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

 width: 100%;  overflow: hidden; z-index: 3;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite;
 

}



#sittingLion {
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/forest3d.2ec714ab.jpeg);
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 

}
#sittingLion2{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/flower2.gif") ; */
  background: url(/static/media/city8.fac597c1.jpeg); 
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#sittingLion3{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/waterfall-3d.e7a077ef.jpeg);
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#forestsection {
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/forest2.9d3c24bf.gif);
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;

 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 

}

#postersection{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/city3.246665b7.jpeg);
  background-size: 100% 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 
   width: 100%;  overflow: hidden; z-index: 0;
   height:100vh;
   width: 100vw;
  /*-moz-animation: move-1  30s 1s ease-in-out infinite;
  -o-animation: move-1  30s 1s  ease-in-out infinite;    
  -webkit-animation: move-1  30s 1s  ease-in-out infinite;
  animation: move-1 30s 1s   ease-in-out infinite; */

}
#postersection2{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/city7.b95dd564.jpeg);
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}
#postersection4{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/city6.c4c997a9.jpeg);
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}
#postersection3{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/city3.246665b7.jpeg);
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /*-moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

.postercuteimage
{
  background: url(/static/media/test23-2.b3f44394.jpeg);
  background-size: 40% 100%;
 
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 z-index: 1;
  width: 50%;  overflow: hidden; 
  -webkit-transform: scale(1) rotateY(0deg); 
          transform: scale(1) rotateY(0deg);
  -webkit-transform-origin: 65% 65%;
  transform-origin: -30% 70%;
 
  height:100vh;
  width: 100vw;
  max-width: 100vw;

  margin-right: 30vw;
  margin-top: 5vw;
  margin-left: 20vw;
  -webkit-transform:  perspective(1000px) rotateY(0deg) rotateX(0deg) rotateZ(1deg);
  transform:  perspective(1000px) rotateY(10deg) rotateX(0deg) rotateZ(0deg);
  

}

#officeenvironment{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  background: url(/static/media/ownoffice1.aaeb7f24.jpeg);
 
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

@media  screen and (max-width: 600px) {
  /* Small screen, non-retina */
  .cat-bed3{
    background: url(/static/media/Lion3.839cd615.gif);
    background-size: contain;

  }

  #seawelcome-shark{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/ocean-frontscreen-shark2.961c81ef.gif);
   background-size: contain;
  
  }
  
  
  
  #seawelcome4{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/ocean-frontscreen-dolphin.b0dd18d9.gif);
   background-size: contain;
  
  }
  
  #seawelcome3{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/ocean-frontscreen-ship.23817f7c.gif);
   background-size: contain;
  
  }
  
  
  #seawelcome2{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/ocean-frontscreen-ship-waves.b5a2d963.gif);
   background-size: contain;
  
  }
  
  
  #seawelcome{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/ocean-frontscreen-waves.da52ec52.gif);
   background-size: contain;
  
  }
  
  #officebuilding{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/office-building.c3bec364.jpeg);
   background-size: contain;
   position:fixed ;

  
   
  }
  
  #officebuilding2{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/ownoffice2.02f06523.jpeg);
   background-size: contain;
   position:fixed ;
  }
  
  #officebuilding3{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/test26.e5f7fc1d.jpeg);
   background-size: contain;
   position:fixed ;
  }
  
  #officebuilding4{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/ownoffice-5.c21215f5.jpeg);
   background-size: contain;
   position:fixed ;
  }
  
  
  #officescreen-rupa{
    background: url(/static/media/rupa-10.31fcb3bf.jpeg);    
   background-size: contain;
   position:fixed ;
  
  }
  
  #officescreen-rupa2{
    background: url(/static/media/Test103-4.efaf9738.jpeg);
   background-size: contain;
   position:fixed ;
   
  }
  #circle-rupa{
    background: url(/static/media/rupa-10.31fcb3bf.jpeg);
    background-size: contain;
   position:fixed ;
   bottom: 0;
  }
 
}
/* end of media screen*/

#seawelcome-shark{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/ocean-frontscreen-shark2.961c81ef.gif);
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}



#seawelcome4{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/ocean-frontscreen-dolphin.b0dd18d9.gif);
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#seawelcome3{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/ocean-frontscreen-ship.23817f7c.gif);
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}


#seawelcome2{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/ocean-frontscreen-ship-waves.b5a2d963.gif);
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}


#seawelcome{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/ocean-frontscreen-waves.da52ec52.gif);
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}
#lastSection{

 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
}

#officebuilding{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/office-building.c3bec364.jpeg);
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 -webkit-animation:  changePage5BackgroundImage  30s infinite ease-in-out;
         animation:  changePage5BackgroundImage  30s infinite ease-in-out;

}


#changebgimage{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/test15-rm.ad7b6b00.jpeg);
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */
 -webkit-animation: 50s changeBackgroundImage2 infinite linear;
         animation: 50s changeBackgroundImage2 infinite linear;

}
#officebuilding2{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/ownoffice2.02f06523.jpeg);
 background-size: 100% 100%;

 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#officebuilding3{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/test26.e5f7fc1d.jpeg);
 background-size: 100% 200%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}

#officebuilding4{
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/office-environment.jpg") ; */
  background: url(/static/media/ownoffice-5.c21215f5.jpeg);
 background-size: 100% 100%;
 display: flex;
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

  width: 100%;  overflow: hidden; z-index: 0;
  height:100vh;
  width: 100vw;
  
 /* -moz-animation: move-1  30s 1s ease-in-out infinite;
 -o-animation: move-1  30s 1s  ease-in-out infinite;    
 -webkit-animation: move-1  30s 1s  ease-in-out infinite;
 animation: move-1 30s 1s   ease-in-out infinite; */

}


#officescreen-rupa{
  background: url(/static/media/rupa-10.31fcb3bf.jpeg);
  
 background-size: 100% 100%;
 
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
  -webkit-transform: scale(4) rotateY(100deg); 
          transform: scale(4) rotateY(100deg);
  -webkit-transform-origin: 65% 65%;
  transform-origin: 65% 65%;
 margin-right: auto;


 

 top: 1;
 bottom: 0;
 left: 0;
 right: 0;
z-index: 1;
 
height:100vh;
width: 100vw;

}

#officescreen-rupa2{
  background: url(/static/media/Test103-4.efaf9738.jpeg);
  

 background-size: 80% 100%;
 
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
z-index: 1;
 width: 50%;  overflow: hidden; 
 -webkit-transform: scale(1) rotateY(0deg); 
         transform: scale(1) rotateY(0deg);
 -webkit-transform-origin: 65% 65%;
 transform-origin: -30% 70%;

 height:100vh;
 width: 100vw;
 
}

#officescreen-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#officescreen-coloumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 426px) {


}

@media screen and (max-width: 426px) {
  #officeenvironment{
    background-size: 100% 50%;
  }

  #officebuilding2{
   background-size: 100% 60%;
  }
 }
@media screen and (min-width: 769px) {
  #officeenvironment{
  }
}

#circle-rupa{
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/Lion3.gif") ; */
  background: url(/static/media/rupa-10.31fcb3bf.jpeg);
  
 background-size: 60% 80%;
 
 background-repeat: no-repeat;
 background-position: center center;
 background-color: transparent;
 position: relative; 
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
z-index: 1;
 width: 70%;  overflow: hidden;    
 -webkit-animation: rupamove  30s 1s  ease-in-out infinite;
 animation: rupamove 30s 1s   ease-in-out infinite;         
 

}


 #circle {
  
   /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
   /* background: url("../images/Lion3.gif") ; */
   background: url(/static/media/Lion3.839cd615.gif);
   
  background-size: 60% 30%;
  
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 
  width: 50%;  overflow: hidden; z-index: 3;    
  -webkit-animation: move-1  30s 1s  ease-in-out infinite;
  animation: move-1 30s 1s   ease-in-out infinite;
  

}


 #circle text {
  
  font-family: 'Helvetica Neue', Arial; font-size: 20px; font-weight: bold; 

}
 

 #circle svg { 
   
  position: relative;   height: inherit; z-index: 0;


   -webkit-animation-name: rotate;
           animation-name: rotate;
   -webkit-animation-duration: 5s;
           animation-duration: 5s;
   -webkit-animation-iteration-count: infinite;
           animation-iteration-count: infinite;
   -webkit-animation-timing-function: linear;
           animation-timing-function: linear;
 
 }
 
 @-webkit-keyframes rotate {
     from { -webkit-transform: rotate(360deg); }
     to { -webkit-transform: rotate(0); }
 }
 @keyframes rotate {
     from { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
     to { -webkit-transform: rotate(0); transform: rotate(0); }
 }
 
 @-webkit-keyframes move-1{
  0%{
    -webkit-transform: scale(3) rotate(0deg);
            transform: scale(3) rotate(0deg);
     -webkit-transform-origin: 35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
  
    50% {
      -webkit-transform: scale(3) rotate(0deg);
              transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
    60%{
      -webkit-transform: scale(3) rotate(-5deg);
              transform: scale(3) rotate(-5deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }
     70%{
      -webkit-transform: scale(3) rotate(0deg);
              transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }

    100%{
     -webkit-transform: scale(3) rotate(-5deg);
             transform: scale(3) rotate(-5deg);
     -webkit-transform-origin:  35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  
    }
  
  }
 
 @keyframes move-1{
  0%{
    -webkit-transform: scale(3) rotate(0deg);
            transform: scale(3) rotate(0deg);
     -webkit-transform-origin: 35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      -webkit-transform: scale(3) rotate(0deg);
              transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
  
    50% {
      -webkit-transform: scale(3) rotate(0deg);
              transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
    60%{
      -webkit-transform: scale(3) rotate(0deg);
              transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }
     70%{
      -webkit-transform: scale(3) rotate(0deg);
              transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }

    100%{
     -webkit-transform: scale(3) rotate(0deg);
             transform: scale(3) rotate(0deg);
     -webkit-transform-origin:  35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  
    }
  
  }

  /*keep original for lion
  
   
 @keyframes move-1{
  0%{
    transform: scale(3) rotate(0deg);
     -webkit-transform-origin: 35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  }
    

    40% {
      transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
  
    50% {
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin: 35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
    }
    60%{
      transform: scale(3) rotate(-5deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }
     70%{
      transform: scale(3) rotate(0deg);
      -webkit-transform-origin:  35% 45%;
      transform-origin: 35% 45%;
     margin-right: auto;
   
     }

    100%{
     transform: scale(3) rotate(-5deg);
     -webkit-transform-origin:  35% 45%;
     transform-origin: 35% 45%;
    margin-right: auto;
  
    }
  
  }
  
  
  
  */
 /*brand flip image end*/
 /*menu 3d start */
 ul {
  position: relative;
  -webkit-transform: rotate(-5deg) skew(180deg, 5deg);
          transform: rotate(-5deg) skew(180deg, 5deg);
}

.list-item {
  background: #a600ff;
  color: #575757;
  text-align: center;
  height: 2.5em;
  width: 6em;
  vertical-align: left;
  line-height: 2.5em;
  border-bottom: 1px solid #060606;
  position: relative;
  display: block;
  text-decoration: none;
  /* box-shadow: -2em 1.5em 0 #e1e1e1; */
  transition: all 0.25s linear;
}
.list-item:hover {
  background: #ff6e42;
  color: #fffcfb;
  -webkit-transform: translate(0.9em, -0.9em);
          transform: translate(0.9em, -0.9em);
  transition: all 0.25s linear;
  box-shadow: -2em 2em 0 #e1e1e1;
}
.list-item:hover:before, .list-item:hover:after {
  transition: all 0.25s linear;
}
.list-item:hover:before {
  background: #b65234;
  width: 1em;
  top: 0.5em;
  left: -1em;
}
.list-item:hover:after {
  background: #b65234;
  width: 1em;
  bottom: -2.5em;
  left: 1em;
  height: 4em;
}
.list-item:before, .list-item:after {
  content: "";
  position: absolute;
  transition: all 0.25s linear;
  width: 0.5em;
}
.list-item:after {
  height: 4em;
  background: #181818;
  bottom: -2.25em;
  left: 1.5em;
  -webkit-transform: rotate(90deg) skew(0, 45deg);
          transform: rotate(90deg) skew(0, 45deg);
}
.list-item:before {
  height: 2.5em;
  background: #121212;
  top: 0.25em;
  left: -0.5em;
  -webkit-transform: skewY(-45deg);
          transform: skewY(-45deg);
}

 /*menu 3d end */

 /*tiger walk*/
 .tuna {
  /* steps causes the animation to chunk into 12 equal pieces */
  -webkit-animation: walk-cycle 2s steps(12) infinite;
          animation: walk-cycle 2s steps(12) infinite;
  background: url(/static/media/tiger-phases.bbe3346d.jpeg) 0 0 no-repeat; 
  height: 100px;
  width: 200px;
  margin: 100px auto 0;
}

@-webkit-keyframes walk-cycle {  
  0% {background-position: 0 0; } 
  100% {background-position: 0 -2391px; } /* Must be full height of sprite or skipping will happen.*/
}

@keyframes walk-cycle {  
  0% {background-position: 0 0; } 
  100% {background-position: 0 -2391px; } /* Must be full height of sprite or skipping will happen.*/
}

.walking
{
   


}

.cat-bed3
{
  background: url(/static/media/Lion3.839cd615.gif);
  background-size: 70% 100%;
 
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: relative; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
 z-index: 1;
  width: 50%;  overflow: hidden; 
  -webkit-transform: scale(1) rotateY(0deg); 
          transform: scale(1) rotateY(0deg);
  -webkit-transform-origin: 65% 65%;
  transform-origin: -30% 70%;
 
  height:100vh;
  width: 100vw;
  max-width: 100vw;
  

}


.cat-bed2
{
  background: url(/static/media/tigre-gif-3-animation.72d742ab.gif);
   height: 400px;
   width: 400px;
   top: 1%;
   margin-right: 1rem;
   z-index: 300;
    -webkit-animation:tigermove 15s steps(15) infinite;
    animation:tigermove 25s steps(25) infinite;
}



.cat-bed
{
  background: url(/static/media/tiger3d-nobg.b0424ca6.jpeg);
   height: 500px;
   width: 500px;
   top: 1%;
   margin-right: 1rem;
   z-index: 300;
 
    -webkit-animation:tigermove 15s steps(15) infinite;
    animation:tigermove 25s steps(25) infinite;
}

.cat-bed text {
  
  font-family: 'Helvetica Neue', Arial; font-size: 30px; font-weight: bold; 

}

.cat-bed svg { 
   
  position: relative;   height: inherit; z-index: 0;
  top:-9rem;
  left: 2rem;
  right: auto;
  bottom: 0;

  /* -webkit-animation-name: rotate;
      -moz-animation-name: rotate;
       -ms-animation-name: rotate;
        -o-animation-name: rotate;
           animation-name: rotate;
   -webkit-animation-duration: 5s;
      -moz-animation-duration: 5s;
       -ms-animation-duration: 5s;
        -o-animation-duration: 5s;
           animation-duration: 5s;
   -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
       -ms-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
           animation-iteration-count: infinite;
   -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
       -ms-animation-timing-function: linear;
        -o-animation-timing-function: linear;
           animation-timing-function: linear;
 */
 }
 


@keyframes tigermove
{  
  0% {margin-right: 100%; -webkit-margin-end:100% ;-webkit-transform:  perspective(1000px)  15deg;transform:  perspective(1000px)  15deg} 
  40% {margin-right: 100%; -webkit-margin-end:50% ;-webkit-transform:  perspective(1000px) rotateY(-15deg);transform:  perspective(1000px) rotateY(-15deg) }
 
  100% {margin-right: 100%; -webkit-margin-end:0% ;-webkit-transform:  perspective(1000px) rotateY(15deg);transform:  perspective(1000px) rotateY(15deg) } 
}   

@-webkit-keyframes tigermove 
{
  0% {margin-right: 100%; -webkit-margin-end:100% ;-webkit-transform:  perspective(1000px)  15deg;transform:  perspective(1000px)  15deg} 
  40% {margin-right: 0%; -webkit-margin-end:0% ;-webkit-transform:  perspective(1000px) rotateY(-15deg);transform:  perspective(1000px) rotateY(-15deg) }
  50% {margin-right: 0%; -webkit-margin-end:0% ;-webkit-transform:  perspective(1000px) rotateY(-175deg);transform:  perspective(1000px) rotateY(-175deg) }
  50% {margin-right: 20%; -webkit-margin-end:20% ;-webkit-transform:  perspective(1000px) rotateY(-175deg);transform:  perspective(1000px) rotateY(-175deg) }
  100% {margin-right: 100%; -webkit-margin-end:100% ;-webkit-transform:  perspective(1000px) rotateY(-175deg);transform:  perspective(1000px) rotateY(-175deg) } 
}


@keyframes tigerwalk
{  
    from {background-position: 0 -2391px; } 
    to {background-position:  0 0;}
}   

@-webkit-keyframes tigerwalk 
{
    from {background-position: 0 0; } 
    to {background-position: 0 -2391px; }
}


@-webkit-keyframes mover {
  0% { -webkit-transform: translateY(0); transform: translateY(0); }
  100% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}
@keyframes mover {
  0% {
    -webkit-transform: scale(0.3) translateY(-100vw);
            transform: scale(0.3) translateY(-100vw);
  }
  10% {
    -webkit-transform: translateY(2vh) translateY(10vw) scale(0.4);
            transform: translateY(2vh) translateY(10vw) scale(0.4);
  }
  20% {
    -webkit-transform: translateY(0vh) translateY(30vw) scale(0.5);
            transform: translateY(0vh) translateY(30vw) scale(0.5);
  }
  30% {
    -webkit-transform: translateY(4vh) translateY(50vw) scale(0.6);
            transform: translateY(4vh) translateY(50vw) scale(0.6);
  }
  40% {
    -webkit-transform: translateY(2vh) translateY(70vw) scale(0.6);
            transform: translateY(2vh) translateY(70vw) scale(0.6);
  }
  50% {
    -webkit-transform: translateY(0vh) translateY(90vw) scale(0.6);
            transform: translateY(0vh) translateY(90vw) scale(0.6);
  }
  60% {
    -webkit-transform: translateY(0vh) translateY(110vw) scale(0.6);
            transform: translateY(0vh) translateY(110vw) scale(0.6);
  }
  100% {
    -webkit-transform: translateY(0vh) translateY(110vw) scale(0.6);
            transform: translateY(0vh) translateY(110vw) scale(0.6);
  }
}
 /*tiger walk end*/

 /*rupamove start*/
 @-webkit-keyframes rupamove{
  0%{
    -webkit-transform: scale(3) rotate(5deg);
            transform: scale(3) rotate(5deg);
     -webkit-transform-origin: 35% 65%;
     transform-origin: 35% 65%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
    }
  
    50% {
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
    }
    60%{
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin:  35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }
     70%{
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 35% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }

    100%{
     -webkit-transform: scale(3) rotate(5deg);
             transform: scale(3) rotate(5deg);
     -webkit-transform-origin:  35% 65%;
     transform-origin: 35% 65%;
    margin-right: auto;
  
    }
  
  }
 
 @keyframes rupamove{
  0%{
    -webkit-transform: scale(3) rotate(5deg);
            transform: scale(3) rotate(5deg);
     -webkit-transform-origin: 45% 65%;
     transform-origin: 45% 65%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
    }
  
    50% {
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
    }
    60%{
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin:  45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
   
     }
     70%{
      -webkit-transform: scale(3) rotate(5deg);
              transform: scale(3) rotate(5deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
   
     }

    100%{
     -webkit-transform: scale(3) rotate(5deg);
             transform: scale(3) rotate(5deg);
     -webkit-transform-origin:  45% 65%;
     transform-origin: 45% 65%;
    margin-right: auto;
  
    }
  
  }

 /*rupa move end*/



 /*humming bird*/
 #humming-bird{
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/Lion3.gif") ; */
  background: url(/static/media/humming-bird2-2.207be5d1.gif);
  
 background-size: 50% 10%;
 
 background-repeat: no-repeat;
 background-position: center right;
 background-color: transparent;
 position: absolute; 
 margin-left: 5em;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

 width: 50%;  overflow: hidden; z-index: 3;    
 -webkit-animation: loginzoom  30s 1s  ease-in-out infinite;
 animation: loginzoom 30s 1s   ease-in-out infinite; 
 

}

#humming-bird2{
  
  /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
  /* background: url("../images/Lion3.gif") ; */
  background: url(/static/media/humming-bird2-2.207be5d1.gif);
  
 background-size: 50% 10%;
 
 background-repeat: no-repeat;
 background-position: center right;
 background-color: transparent;
 position: absolute; 
 margin-left: 5em;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;

 width: 10%;  overflow: hidden; z-index: 3;    
 -webkit-animation: loginzoom  30s 1s  ease-in-out infinite;
 animation: loginzoom 30s 1s   ease-in-out infinite; 
 

}

.LoginButton-availability{

/* 
   overflow: hidden; z-index: 99;
 -moz-animation: loginzoom  30s 1s ease-in-out infinite;
 -o-animation: loginzoom  30s 1s  ease-in-out infinite;    
 -webkit-animation: loginzoom  30s 1s  ease-in-out infinite;
 animation: loginzoom 30s 1s   ease-in-out infinite;
  */

}

@-webkit-keyframes loginzoom{

  0%{
      -webkit-transform: scale(1) rotate(-10deg);
              transform: scale(1) rotate(-10deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 55% 95%;
      margin-right: auto;
    }
  
    0%{
      -webkit-transform: scale(1) rotate(0deg);
              transform: scale(1) rotate(0deg);
       -webkit-transform-origin: 65% 65%;
       transform-origin: 65% 95%;
      margin-right: auto;
    }
      /* Finish changes by here */
  
      40% {
        -webkit-transform: scale(1) rotate(5deg);
                transform: scale(1) rotate(5deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 5% 35%;
       margin-right: auto;
      }
    
      50% {
        -webkit-transform: scale(1) rotate(25deg);
                transform: scale(1) rotate(25deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 0% 5%;
       margin-right: auto;
      }
      60%{
        -webkit-transform: scale(1) rotate(5deg);
                transform: scale(1) rotate(5deg);
        -webkit-transform-origin:  45% 65%;
        transform-origin: 35% 65%;
       margin-right: auto;
     
       }
       70%{
        -webkit-transform: scale(1) rotate(35deg);
                transform: scale(1) rotate(35deg);
        -webkit-transform-origin: 45% 65%;
        transform-origin: 45% 65%;
       margin-right: auto;
     
       }
  
      100%{
       -webkit-transform: scale(1) rotate(5deg);
               transform: scale(1) rotate(5deg);
       -webkit-transform-origin:  45% 65%;
       transform-origin: 45% 65%;
      margin-right: auto;
    
      }
  
  }

@keyframes loginzoom{
  0%{
    -webkit-transform: scale(1) rotate(-10deg);
            transform: scale(1) rotate(-10deg);
     -webkit-transform-origin: 65% 65%;
     transform-origin: 55% 95%;
    margin-right: auto;
  }

  0%{
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
     -webkit-transform-origin: 65% 65%;
     transform-origin: 65% 95%;
    margin-right: auto;
  }
    /* Finish changes by here */

    40% {
      -webkit-transform: scale(1) rotate(5deg);
              transform: scale(1) rotate(5deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 5% 35%;
     margin-right: auto;
    }
  
    50% {
      -webkit-transform: scale(1) rotate(25deg);
              transform: scale(1) rotate(25deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 0% 5%;
     margin-right: auto;
    }
    60%{
      -webkit-transform: scale(1) rotate(5deg);
              transform: scale(1) rotate(5deg);
      -webkit-transform-origin:  45% 65%;
      transform-origin: 35% 65%;
     margin-right: auto;
   
     }
     70%{
      -webkit-transform: scale(1) rotate(35deg);
              transform: scale(1) rotate(35deg);
      -webkit-transform-origin: 45% 65%;
      transform-origin: 45% 65%;
     margin-right: auto;
   
     }

    100%{
     -webkit-transform: scale(1) rotate(5deg);
             transform: scale(1) rotate(5deg);
     -webkit-transform-origin:  45% 65%;
     transform-origin: 45% 65%;
    margin-right: auto;
  
    }
  
  }


  #water-waves-section{
    background: url(/static/media/ocean-carribean-water.2ff3081d.gif);
    
  
   background-size: 100% 100%;
   
   background-repeat: no-repeat;
   background-position: center center;
   background-color: transparent;
   position: relative; 
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
  z-index: 1;
    overflow: hidden; 
 
  
   height:100vh;
   width: 100vw;
   
  }

  #welcome-3d{
   
    height: 100%;
    /* max-height: 600px; */
    width: 1000px;
    height:100vh;
    width: 100vw;
    background-color: hsla(200, 40%, 30%, 0.4);
    background-image: 
      url(/static/media/3d-pic1.a5084cdf.png),
      url(/static/media/3d-pic2.01cfc1b5.png),
      url(/static/media/3d-pic3.3637dd13.png),
      url(/static/media/3d-pic4.08d584db.png);
      
    background-repeat: repeat-x;
    background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
    background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
    -webkit-animation: 50s para infinite linear;
            animation: 50s para infinite linear;
  }

  @-webkit-keyframes para {
    100% {
      background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 400px 0;
    }
  }

  @keyframes para {
    100% {
      background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 400px 0;
    }
  }

  #change-bckimages{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/office-building.c3bec364.jpeg);
   background-size: 100% 100%;
   display: flex;
   background-repeat: no-repeat;
   background-position: center center;
   background-color: transparent;
   position: relative; 
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
  
    width: 100%;  overflow: hidden; z-index: 0;
    height:100vh;
    width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

   -webkit-animation: changePage5BackgroundImage 13s infinite;

           animation: changePage5BackgroundImage 13s infinite;
  
  }

  #page5-change-background{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    
   background-size: 100% 100%;
   display: flex;
   background-repeat: no-repeat;
   background-position: center center;
   background-color: transparent;
   position: relative; 
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
  
    width: 100%;  overflow: hidden; z-index: 0;
    height:100vh;
    width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */
  
   -webkit-animation-name: changePage5BackgroundImage;
  
           animation-name: changePage5BackgroundImage;
   -webkit-animation-duration:60s;
           animation-duration:60s;
   -webkit-animation-timing-function: ease-in-out;
           animation-timing-function: ease-in-out;
   -webkit-animation-iteration-count: infinite;
           animation-iteration-count: infinite;
   -webkit-animation-delay: 1s;
           animation-delay: 1s;
   -webkit-transform:  perspective(1000px)  ;
           transform:  perspective(1000px)  ;
  
  }
  
  
@-webkit-keyframes changeBackgroundImage {
  0% {
    background: url(/static/media/office-building.c3bec364.jpeg);
    /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
  }
  25% {
    background-image: url(/static/media/login-change-bk1.ec533766.jpg);
  }
  50% {
    background-image: url(/static/media/login-change-bk2.5d26d938.jpg);
  }
  75% {
    background-image: url(/static/media/login-change-bk3.64fda4f3.jpg);
  }
  100% {
    background-image: url(/static/media/login-change-bk4.3389b719.jpg);
  }
}
  
  
@keyframes changeBackgroundImage {
  0% {
    background: url(/static/media/office-building.c3bec364.jpeg);
    /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
  }
  25% {
    background-image: url(/static/media/login-change-bk1.ec533766.jpg);
  }
  50% {
    background-image: url(/static/media/login-change-bk2.5d26d938.jpg);
  }
  75% {
    background-image: url(/static/media/login-change-bk3.64fda4f3.jpg);
  }
  100% {
    background-image: url(/static/media/login-change-bk4.3389b719.jpg);
  }
}

@-webkit-keyframes para2 {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 400px 0;
  }
}

@keyframes para2 {
  100% {
    background-position: -5000px 20%, -800px 95%, 500px 50%, 1000px 100%, 400px 0;
  }
}
  @-webkit-keyframes changeBackgroundImage2 {
    0% {
      background: url(/static/media/test15-rm.ad7b6b00.jpeg);
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    }
    25% {
      background-image: url(/static/media/test23-2.b3f44394.jpeg);
    }
    50% {
      background-image: url(/static/media/test26-2.a8909e0d.jpeg);
    }
    75% {
      background-image: url(/static/media/test27-2.c33f907b.jpeg);
    }
    100% {
      background-image: url(/static/media/Test103-4.efaf9738.jpeg);
    }
  }
  @keyframes changeBackgroundImage2 {
    0% {
      background: url(/static/media/test15-rm.ad7b6b00.jpeg);
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    }
    25% {
      background-image: url(/static/media/test23-2.b3f44394.jpeg);
    }
    50% {
      background-image: url(/static/media/test26-2.a8909e0d.jpeg);
    }
    75% {
      background-image: url(/static/media/test27-2.c33f907b.jpeg);
    }
    100% {
      background-image: url(/static/media/Test103-4.efaf9738.jpeg);
    }
  }


  @-webkit-keyframes changePage5BackgroundImage {
    0% {
      background-image: url(/static/media/office-building.c3bec364.jpeg);
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
    }
    25% {
      background-image: url(/static/media/bullet-train.0cc7fd12.jpeg);
    
    }
    50% {
      background-image: url(/static/media/airplane.593d6836.jpeg);
    }
    75% {
      background-image: url(/static/media/login-change-bk3.64fda4f3.jpg);
    }
    100% {
      background-image: url(/static/media/login-change-bk4.3389b719.jpg);
    }
  }


  @keyframes changePage5BackgroundImage {
    0% {
      background-image: url(/static/media/office-building.c3bec364.jpeg);
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
    }
    25% {
      background-image: url(/static/media/bullet-train.0cc7fd12.jpeg);
    
    }
    50% {
      background-image: url(/static/media/airplane.593d6836.jpeg);
    }
    75% {
      background-image: url(/static/media/login-change-bk3.64fda4f3.jpg);
    }
    100% {
      background-image: url(/static/media/login-change-bk4.3389b719.jpg);
    }
  }
  
 /* img {
    height: 100vh;
    width: 100%;
    object-fit: contain;
    background: transparent;
  }
  */

 
  /* login index */ 
  #loginIndex1{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/test29.39597af8.jpeg);
    background-size: 100%  100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #loginIndex2{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/test28.2ca764a6.jpeg);
    background-size: 100% 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #loginIndex3{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/test30.49f0293e.jpeg);
    background-size: auto 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #loginIndex4{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/test31.1f38dc8c.jpeg);
    background-size: auto 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #index-next-button{
    
    -webkit-animation: 50s nextbutton-float-up-1 infinite linear;
    
            animation: 50s nextbutton-float-up-1 infinite linear;
  }
  @keyframes changeBackgroundImage2 {
    0% {
      background-image: url(/static/media/test28.2ca764a6.jpeg);
      /* background-image: url('https://picsum.photos/id/1015/1500/1000'); */
    
    }
    30% {
      background-image: url(/static/media/test29.39597af8.jpeg);
    
    }
    60% {
      background-image: url(/static/media/test30.49f0293e.jpeg);
    }
    100% {
      background-image: url(/static/media/test31.1f38dc8c.jpeg);
    }
    
  }
  @keyframes nextbutton-float-up-1 {
    0% { -webkit-transform: translateY(0vh); transform: translateY(0vh);   }
    10% { -webkit-transform: translateY(-10vh); transform: translateY(-10vh) } 
    100% { -webkit-transform: translateY(-10vh); transform: translateY(-10vh) }                       
    }
    @-webkit-keyframes nextbutton-float-up-1 {
      0% { -webkit-transform: translateY(0vh); transform: translateY(0vh);   }
  
      10% { -webkit-transform: translateY(-10vh); transform: translateY(-10vh) }   
      100% { -webkit-transform: translateY(-10vh); transform: translateY(-10vh) }     
    }

  /* login index changes end */
* {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
}


.imgLoader {
  position: fixed;
  -webkit-animation: preLoad 1s steps(1);
          animation: preLoad 1s steps(1);
  width: 1px;
  height: 1px;
}
@-webkit-keyframes preLoad {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
  }
  10% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
  }
  20% {
    background-image: url(/static/media/test17.8103f819.jpeg);
  }
  30% {
    background-image: url(/static/media/test13.cd076653.jpeg);
  }
  40% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
  }
  100% {
    display: none;
  }
}
@keyframes preLoad {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
  }
  10% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
  }
  20% {
    background-image: url(/static/media/test17.8103f819.jpeg);
  }
  30% {
    background-image: url(/static/media/test13.cd076653.jpeg);
  }
  40% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
  }
  100% {
    display: none;
  }
}

.container {
  text-transform: uppercase;
  position: relative;
  width: 500px;
  border: #fff solid 0px;
  border-radius: 0px;
  height: 500px;
  
}

.title {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  font-size: 2em;
  font-weight: normal;
  color: gold;
  line-height: 1;
}

.credit {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 0.9em;
  text-align: left;
}

.book {
  position: relative;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-perspective-origin: center 50px;
          perspective-origin: center 50px;
  -webkit-filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
  
  -webkit-transform: scale(1) rotateY(-55deg);
  
          transform: scale(1) rotateY(-55deg);
  -webkit-transform-origin: 5% 5%;
  transform-origin: 5% 5%;
}

.page {
  width: 210px;
  height: 300px;
  background-color: #bbb;
  position: absolute;
  top: 0px;
  right: 50%;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  border: solid #555 2px;
  background-size: 420px 300px;
  background-position: center;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;

}
.page:nth-child(1) {
  -webkit-transform: rotateX(60deg) rotateY(3deg);
          transform: rotateX(60deg) rotateY(3deg);
}
.page:nth-child(2) {
  -webkit-transform: rotateX(60deg) rotateY(4.5deg);
          transform: rotateX(60deg) rotateY(4.5deg);
}
.page:nth-child(3) {
  -webkit-transform: rotateX(60deg) rotateY(6deg);
          transform: rotateX(60deg) rotateY(6deg);
  -webkit-animation: nextPage 25s infinite -24s steps(1);
          animation: nextPage 25s infinite -24s steps(1);
  background-size: 420px 300px;
  background-position: -2px -2px;
}
.page:nth-child(4) {
  -webkit-transform: rotateX(60deg) rotateY(177deg);
          transform: rotateX(60deg) rotateY(177deg);
}
.page:nth-child(5) {
  -webkit-transform: rotateX(60deg) rotateY(175.5deg);
          transform: rotateX(60deg) rotateY(175.5deg);
}
.page:nth-child(6) {
  -webkit-transform: rotateX(60deg) rotateY(174deg);
          transform: rotateX(60deg) rotateY(174deg);
  overflow: hidden;
}
.page:nth-child(6)::after {
  content: "";
  width: 210px;
  height: 300px;
  position: absolute;
  top: 0px;
  right: 0%;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-animation: nextPage 25s -20s infinite steps(1);
          animation: nextPage 25s -20s infinite steps(1);
  background-size: 420px 300px;
  background-position: 100% -2px;
}
@-webkit-keyframes nextPage {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
  }
}
@keyframes nextPage {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
  }
}

.gap {
  width: 10px;
  height: 300px;
  background: none;
  -webkit-transform: rotateX(60deg);
          transform: rotateX(60deg);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  position: absolute;
  top: 0px;
  left: calc(50% - 5px);
}
.gap::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  background-color: #555;
  width: 10px;
  height: 5px;
  border-radius: 50%;
}

.pages, .flips {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flip {
  width: 32px;
  height: 300px;
  position: absolute;
  top: 0px;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  right: 100%;
  border: solid #555;
  border-width: 2px 0px;
  -webkit-perspective: 4200px;
          perspective: 4200px;
  -webkit-perspective-origin: center;
          perspective-origin: center;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  background-size: 420px 300px;
}
.flip::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: center;
          transform-origin: center;
  background-size: 420px 300px;
}
.flip.flip1 {
  right: 50%;
  -webkit-animation: flip1 5s infinite ease-in-out;
          animation: flip1 5s infinite ease-in-out;
  border-width: 2px 2px 2px 0;
}
.flip.flip1::after {
  -webkit-animation: nextFlip1 25s -20s infinite steps(1);
          animation: nextFlip1 25s -20s infinite steps(1);
}
.flip:not(.flip1) {
  right: calc(100% - 2px);
  top: -2px;
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-animation: flip2 5s ease-in-out infinite;
          animation: flip2 5s ease-in-out infinite;
}
.flip.flip2::after {
  -webkit-animation: nextFlip2 25s -20s infinite steps(1);
          animation: nextFlip2 25s -20s infinite steps(1);
}
.flip.flip3::after {
  -webkit-animation: nextFlip3 25s -20s infinite steps(1);
          animation: nextFlip3 25s -20s infinite steps(1);
}
.flip.flip4::after {
  -webkit-animation: nextFlip4 25s -20s infinite steps(1);
          animation: nextFlip4 25s -20s infinite steps(1);
}
.flip.flip5::after {
  -webkit-animation: nextFlip5 25s -20s infinite steps(1);
          animation: nextFlip5 25s -20s infinite steps(1);
}
.flip.flip6::after {
  -webkit-animation: nextFlip6 25s -20s infinite steps(1);
          animation: nextFlip6 25s -20s infinite steps(1);
}
.flip.flip7::after {
  -webkit-animation: nextFlip7 25s -20s infinite steps(1);
          animation: nextFlip7 25s -20s infinite steps(1);
}
.flip.flip7 {
  width: 30px;
  border-width: 2px 0px 2px 2px;
}
.flip.flip7::after {
  -webkit-animation: nextFlip7 25s -20s infinite steps(1);
          animation: nextFlip7 25s -20s infinite steps(1);
}
@-webkit-keyframes flip1 {
  0%, 20% {
    -webkit-transform: rotateX(60deg) rotateY(6deg);
            transform: rotateX(60deg) rotateY(6deg);
  }
  80%, 100% {
    -webkit-transform: rotateX(60deg) rotateY(174deg);
            transform: rotateX(60deg) rotateY(174deg);
  }
}
@keyframes flip1 {
  0%, 20% {
    -webkit-transform: rotateX(60deg) rotateY(6deg);
            transform: rotateX(60deg) rotateY(6deg);
  }
  80%, 100% {
    -webkit-transform: rotateX(60deg) rotateY(174deg);
            transform: rotateX(60deg) rotateY(174deg);
  }
}
@-webkit-keyframes flip2 {
  0%, 20% {
    -webkit-transform: rotateY(0deg) translateY(0px);
            transform: rotateY(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateY(-15deg) translateY(0px);
            transform: rotateY(-15deg) translateY(0px);
  }
}
@keyframes flip2 {
  0%, 20% {
    -webkit-transform: rotateY(0deg) translateY(0px);
            transform: rotateY(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateY(-15deg) translateY(0px);
            transform: rotateY(-15deg) translateY(0px);
  }
}

@-webkit-keyframes nextFlip1 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  10% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  30% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  50% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  70% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  90% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}

@keyframes nextFlip1 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  10% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  30% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  50% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  70% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -178px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  90% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -210px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip2 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  10.5% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  30.5% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  50.5% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  70.5% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  90.5% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes nextFlip2 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  10.5% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  30.5% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  50.5% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  70.5% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -148px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  90.5% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -238px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip3 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  11% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  31% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  51% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  71% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  91% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes nextFlip3 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  11% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  31% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  51% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  71% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -118px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  91% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -268px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip4 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  11.5% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  31.5% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  51.5% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  71.5% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  91.5% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes nextFlip4 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  11.5% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  31.5% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  51.5% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  71.5% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -88px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  91.5% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -298px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip5 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  12% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  32% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  52% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  72% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  92% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes nextFlip5 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  12% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  32% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  52% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  72% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -58px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  92% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -328px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip6 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  12.5% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  32.5% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  52.5% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  72.5% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  92.5% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes nextFlip6 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  12.5% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  32.5% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  52.5% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  72.5% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -28px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  92.5% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -358px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip7 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  13% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  33% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  53% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  73% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  93% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes nextFlip7 {
  0% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  13% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  20% {
    background-image: url(/static/media/test15.c33cb764.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  33% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  40% {
    background-image: url(/static/media/test17.8103f819.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  53% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  60% {
    background-image: url(/static/media/test13.cd076653.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  73% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  80% {
    background-image: url(/static/media/test10.8d674dcf.jpeg);
    background-position: -2px -2px;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  93% {
    background-image: url(/static/media/test23.6d596d3f.jpeg);
    background-position: -388px -2px;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
.twitterLink {
  position: fixed;
  bottom: 0.5em;
  right: 0.5em;
}
.twitterLink img {
  width: 2em;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: -webkit-filter 0.25s;
  transition: filter 0.25s;
  transition: filter 0.25s, -webkit-filter 0.25s;
}
.twitterLink img:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}
@font-face {
  font-family: "AmericanCaptain";
  src: url("https://res.cloudinary.com/wyk/raw/upload/v1537995321/ballack/AmericanCaptain.woff2") format("woff2"), url("https://res.cloudinary.com/wyk/raw/upload/v1537995321/ballack/AmericanCaptain.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


.postercontainer {
  margin: auto;
  padding: 50px 10px;
  margin-right: auto;    
  -webkit-animation: spinning-poster  10s 15s  ease-in-out infinite;
  animation: spinning-poster  10s 15s  ease-in-out infinite; 
  /* transform: scale(1) rotateY(5deg);
  -webkit-transform-origin: 5% 5%;
  transform-origin: 45% 5%; */

  -webkit-transform-origin:  75% 45%;
  transform-origin: 75% 45%;
  -webkit-transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
  transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);

}

.hbd {
  text-align: center;
  color: #fff;
  font-family: "Permanent Marker", cursive;
  font-size: 24px;
}
@media (min-width: 768px) {
  .hbd {
    font-size: 3vw;
  }
}
.hbd:first-child span {
  background: linear-gradient(90deg, #e3ffe7 0%, #efd5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  -webkit-animation: shine01 4s linear infinite;
          animation: shine01 4s linear infinite;
}
.hbd:nth-of-type(2) span {
  background: linear-gradient(90deg, #e3ffe7 0%, #efd5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  -webkit-animation: shine02 4s linear infinite;
          animation: shine02 4s linear infinite;
}

.hero {
  position: relative;
  background-size: contain;
  overflow: hidden;
  width: 80%;
  box-shadow: 0px 0px 100px 0px #c80bf780;
  margin: 50px auto;

}
@media (min-width: 768px) {
  .hero {
    width: 60%;
  }
}
@media (min-width: 1000px) {
  .hero {
    width: 40%;
  }
}

.ballack {
  display: block;
  width: 100%;
}

.quote {
  display: grid;
  mix-blend-mode: multiply;
  text-transform: uppercase;
  font-family: "AmericanCaptain", sans-serif;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  width: 50%;
  color: #fff;
  font-size: 11vw;
  text-align: right;
  -webkit-transform: translate(0, -50%) scale(1, 1.3);
          transform: translate(0, -50%) scale(1, 1.3);
  line-height: 0.6;
}
@media (min-width: 768px) {
  .quote {
    font-size: 8.2vw;
  }
}
@media (min-width: 1000px) {
  .quote {
    font-size: 5.2vw;
  }
}
@media (min-width: 1200px) {
  .quote {
    font-size: 4.3vw;
  }
}
.quote::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 200vh;
  background: #ee082ef5;
  z-index: -1;
  -webkit-transform: translate(0, -60%);
          transform: translate(0, -60%);
  opacity: 2;
}

@-webkit-keyframes shine01 {
  to {
    background-position: -200% center;
  }
}

@keyframes shine01 {
  to {
    background-position: -200% center;
  }
}
@-webkit-keyframes shine02 {
  to {
    background-position: 200% center;
  }
}
@keyframes shine02 {
  to {
    background-position: 200% center;
  }
}


@keyframes spinning-poster {
  0% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    z-index: 99;
  }
  20% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(-55deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(-55deg) rotateX(0deg);
    z-index: 99;
  }
  /* 30% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  } */
  30% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);
  }
  100% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);
  }
}

@-webkit-keyframes spinning-poster {
  0% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    z-index: 99;
  }
  20% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(-35deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(-35deg) rotateX(0deg);
    z-index: 99;
  }
  /* 30% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  } */
  30% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
  }
  100% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
  }
}



@keyframes spinning-poster2 {
  
  30% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 80% 15%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  }
  100% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 80% 15%;;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  }
}

@-webkit-keyframes spinning-poster2 {
  30% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 80% 15%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  }
  100% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 80% 15%;;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  }
}
.bot-cover-photo {
    width: 100vw;
}

.chatbot-card {
  background-color: rgba(140, 192, 247, 0.735);
}

.human-input {
    padding-left: 30vw;
    padding-right: 30vw;
    margin: 10px;
}

h3 {
    margin-bottom: 0 !important;
}

#user-input {
    background-color: rgba(132, 136, 249, 0.646);
}

#bot-reply {
    background-color: rgba(231, 228, 228, 0.687);
}

#user-input, #bot-reply {
    padding: 0.4rem;
    padding-left: 1rem;
    border-radius: 10px;
    text-align: left;
}

.conversation-box {
    padding-left: 20vw;
    padding-right: 20vw;
}

.chatbox {
    overflow-y: scroll;
    overflow-y: auto;
    height: 230px;
}
.logoutlabel { 
    
    text-align: center;
    color: rgb(16, 139, 240);
    font-size: 1em;
    transition: 0.5s;
    font-family: Arial, Helvetica, sans-serif;
}
.logoutlabel2 { 
    
    text-align: center;
    color: rgb(240, 16, 16);
    font-size: 1em;
    transition: 0.5s;
    font-family: Arial, Helvetica, sans-serif;
}
.logoutlabel span { color: green; }
#galaxyIndex1{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; 
    background: url("../images/Galaxy-3.gif") ;*/
    background: url(/static/media/Galaxy-3.013840e6.gif);
    background-size: 100% 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }


  #companyglimpse{
    /*background: url("../images/tiger-brand_transparent.jpeg") ;*/
    /* background: url("../images/office-environment.jpg") ; */
    background: url(/static/media/earth.c85b3507.gif);
    background-size: auto 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;

    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }
  #scrollingNames{
    height: 100%;
    overflow: hidden;
    background: black;
    color: gold;
    font-family: 'BenchNine', sans-serif;
    text-transform: uppercase;
    font-size: 49px;
    letter-spacing: -.04rem;
    text-align: center;
    z-index: 99999;
  }

  #newyear2023wishes{
    background: url(/static/media/NewYear2023.f1d365a4.gif);
    background-size: 100% 100%;
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: relative; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
     width: 100%;  overflow: hidden; z-index: 0;
     height:100vh;
     width: 100vw;
    
   /* -moz-animation: move-1  30s 1s ease-in-out infinite;
   -o-animation: move-1  30s 1s  ease-in-out infinite;    
   -webkit-animation: move-1  30s 1s  ease-in-out infinite;
   animation: move-1 30s 1s   ease-in-out infinite; */

  
  }

