@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
* {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
}


.imgLoader {
  position: fixed;
  -webkit-animation: preLoad 1s steps(1);
          animation: preLoad 1s steps(1);
  width: 1px;
  height: 1px;
}
@-webkit-keyframes preLoad {
  0% {
    background-image: url("../images/test23.jpeg");
  }
  10% {
    background-image: url("../images/test15.jpeg");
  }
  20% {
    background-image: url("../images/test17.jpeg");
  }
  30% {
    background-image: url("../images/test13.jpeg");
  }
  40% {
    background-image: url("../images/test10.jpeg");
  }
  100% {
    display: none;
  }
}
@keyframes preLoad {
  0% {
    background-image: url("../images/test23.jpeg");
  }
  10% {
    background-image: url("../images/test15.jpeg");
  }
  20% {
    background-image: url("../images/test17.jpeg");
  }
  30% {
    background-image: url("../images/test13.jpeg");
  }
  40% {
    background-image: url("../images/test10.jpeg");
  }
  100% {
    display: none;
  }
}

.container {
  text-transform: uppercase;
  position: relative;
  width: 500px;
  border: #fff solid 0px;
  border-radius: 0px;
  height: 500px;
  
}

.title {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  font-size: 2em;
  font-weight: normal;
  color: gold;
  line-height: 1;
}

.credit {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 0.9em;
  text-align: left;
}

.book {
  position: relative;
  perspective: 1000px;
  perspective-origin: center 50px;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
  
  transform: scale(1) rotateY(-55deg);
  -webkit-transform-origin: 5% 5%;
  transform-origin: 5% 5%;
}

.page {
  width: 210px;
  height: 300px;
  background-color: #bbb;
  position: absolute;
  top: 0px;
  right: 50%;
  transform-origin: 100% 100%;
  border: solid #555 2px;
  background-size: 420px 300px;
  background-position: center;
  transform-style: preserve-3d;

}
.page:nth-child(1) {
  transform: rotateX(60deg) rotateY(3deg);
}
.page:nth-child(2) {
  transform: rotateX(60deg) rotateY(4.5deg);
}
.page:nth-child(3) {
  transform: rotateX(60deg) rotateY(6deg);
  -webkit-animation: nextPage 25s infinite -24s steps(1);
          animation: nextPage 25s infinite -24s steps(1);
  background-size: 420px 300px;
  background-position: -2px -2px;
}
.page:nth-child(4) {
  transform: rotateX(60deg) rotateY(177deg);
}
.page:nth-child(5) {
  transform: rotateX(60deg) rotateY(175.5deg);
}
.page:nth-child(6) {
  transform: rotateX(60deg) rotateY(174deg);
  overflow: hidden;
}
.page:nth-child(6)::after {
  content: "";
  width: 210px;
  height: 300px;
  position: absolute;
  top: 0px;
  right: 0%;
  transform-origin: center;
  transform: rotateY(180deg);
  -webkit-animation: nextPage 25s -20s infinite steps(1);
          animation: nextPage 25s -20s infinite steps(1);
  background-size: 420px 300px;
  background-position: 100% -2px;
}
@-webkit-keyframes nextPage {
  0% {
    background-image: url("../images/test23.jpeg");
  }
  20% {
    background-image: url("../images/test15.jpeg");
  }
  40% {
    background-image: url("../images/test17.jpeg");
  }
  60% {
    background-image: url("../images/test13.jpeg");
  }
  80% {
    background-image: url("../images/test10.jpeg");
  }
}
@keyframes nextPage {
  0% {
    background-image: url("../images/test23.jpeg");
  }
  20% {
    background-image: url("../images/test15.jpeg");
  }
  40% {
    background-image: url("../images/test17.jpeg");
  }
  60% {
    background-image: url("../images/test13.jpeg");
  }
  80% {
    background-image: url("../images/test10.jpeg");
  }
}

.gap {
  width: 10px;
  height: 300px;
  background: none;
  transform: rotateX(60deg);
  transform-origin: bottom;
  position: absolute;
  top: 0px;
  left: calc(50% - 5px);
}
.gap::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #555;
  width: 10px;
  height: 5px;
  border-radius: 50%;
}

.pages, .flips {
  transform-style: preserve-3d;
}

.flip {
  width: 32px;
  height: 300px;
  position: absolute;
  top: 0px;
  transform-origin: 100% 100%;
  right: 100%;
  border: solid #555;
  border-width: 2px 0px;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  background-size: 420px 300px;
}
.flip::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  height: 100%;
  transform-origin: center;
  background-size: 420px 300px;
}
.flip.flip1 {
  right: 50%;
  -webkit-animation: flip1 5s infinite ease-in-out;
          animation: flip1 5s infinite ease-in-out;
  border-width: 2px 2px 2px 0;
}
.flip.flip1::after {
  -webkit-animation: nextFlip1 25s -20s infinite steps(1);
          animation: nextFlip1 25s -20s infinite steps(1);
}
.flip:not(.flip1) {
  right: calc(100% - 2px);
  top: -2px;
  transform-origin: right;
  -webkit-animation: flip2 5s ease-in-out infinite;
          animation: flip2 5s ease-in-out infinite;
}
.flip.flip2::after {
  -webkit-animation: nextFlip2 25s -20s infinite steps(1);
          animation: nextFlip2 25s -20s infinite steps(1);
}
.flip.flip3::after {
  -webkit-animation: nextFlip3 25s -20s infinite steps(1);
          animation: nextFlip3 25s -20s infinite steps(1);
}
.flip.flip4::after {
  -webkit-animation: nextFlip4 25s -20s infinite steps(1);
          animation: nextFlip4 25s -20s infinite steps(1);
}
.flip.flip5::after {
  -webkit-animation: nextFlip5 25s -20s infinite steps(1);
          animation: nextFlip5 25s -20s infinite steps(1);
}
.flip.flip6::after {
  -webkit-animation: nextFlip6 25s -20s infinite steps(1);
          animation: nextFlip6 25s -20s infinite steps(1);
}
.flip.flip7::after {
  -webkit-animation: nextFlip7 25s -20s infinite steps(1);
          animation: nextFlip7 25s -20s infinite steps(1);
}
.flip.flip7 {
  width: 30px;
  border-width: 2px 0px 2px 2px;
}
.flip.flip7::after {
  -webkit-animation: nextFlip7 25s -20s infinite steps(1);
          animation: nextFlip7 25s -20s infinite steps(1);
}
@-webkit-keyframes flip1 {
  0%, 20% {
    transform: rotateX(60deg) rotateY(6deg);
  }
  80%, 100% {
    transform: rotateX(60deg) rotateY(174deg);
  }
}
@keyframes flip1 {
  0%, 20% {
    transform: rotateX(60deg) rotateY(6deg);
  }
  80%, 100% {
    transform: rotateX(60deg) rotateY(174deg);
  }
}
@-webkit-keyframes flip2 {
  0%, 20% {
    transform: rotateY(0deg) translateY(0px);
  }
  50% {
    transform: rotateY(-15deg) translateY(0px);
  }
}
@keyframes flip2 {
  0%, 20% {
    transform: rotateY(0deg) translateY(0px);
  }
  50% {
    transform: rotateY(-15deg) translateY(0px);
  }
}

@-webkit-keyframes nextFlip1 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  10% {
    background-image: url("../images/test15.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  30% {
    background-image: url("../images/test17.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  50% {
    background-image: url("../images/test13.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  70% {
    background-image: url("../images/test10.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  90% {
    background-image: url("../images/test23.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
}

@keyframes nextFlip1 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  10% {
    background-image: url("../images/test15.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  30% {
    background-image: url("../images/test17.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  50% {
    background-image: url("../images/test13.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  70% {
    background-image: url("../images/test10.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  90% {
    background-image: url("../images/test23.jpeg");
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip2 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  10.5% {
    background-image: url("../images/test15.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  30.5% {
    background-image: url("../images/test17.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  50.5% {
    background-image: url("../images/test13.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  70.5% {
    background-image: url("../images/test10.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  90.5% {
    background-image: url("../images/test23.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip2 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  10.5% {
    background-image: url("../images/test15.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  30.5% {
    background-image: url("../images/test17.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  50.5% {
    background-image: url("../images/test13.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  70.5% {
    background-image: url("../images/test10.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  90.5% {
    background-image: url("../images/test23.jpeg");
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip3 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  11% {
    background-image: url("../images/test15.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  31% {
    background-image: url("../images/test17.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  51% {
    background-image: url("../images/test13.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  71% {
    background-image: url("../images/test10.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  91% {
    background-image: url("../images/test23.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip3 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  11% {
    background-image: url("../images/test15.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  31% {
    background-image: url("../images/test17.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  51% {
    background-image: url("../images/test13.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  71% {
    background-image: url("../images/test10.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  91% {
    background-image: url("../images/test23.jpeg");
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip4 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  11.5% {
    background-image: url("../images/test15.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  31.5% {
    background-image: url("../images/test17.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  51.5% {
    background-image: url("../images/test13.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  71.5% {
    background-image: url("../images/test10.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  91.5% {
    background-image: url("../images/test23.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip4 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  11.5% {
    background-image: url("../images/test15.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  31.5% {
    background-image: url("../images/test17.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  51.5% {
    background-image: url("../images/test13.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  71.5% {
    background-image: url("../images/test10.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  91.5% {
    background-image: url("../images/test23.jpeg");
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip5 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  12% {
    background-image: url("../images/test15.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  32% {
    background-image: url("../images/test17.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  52% {
    background-image: url("../images/test13.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  72% {
    background-image: url("../images/test10.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  92% {
    background-image: url("../images/test23.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip5 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  12% {
    background-image: url("../images/test15.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  32% {
    background-image: url("../images/test17.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  52% {
    background-image: url("../images/test13.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  72% {
    background-image: url("../images/test10.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  92% {
    background-image: url("../images/test23.jpeg");
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip6 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  12.5% {
    background-image: url("../images/test15.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  32.5% {
    background-image: url("../images/test17.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  52.5% {
    background-image: url("../images/test13.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  72.5% {
    background-image: url("../images/test10.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  92.5% {
    background-image: url("../images/test23.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip6 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  12.5% {
    background-image: url("../images/test15.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  32.5% {
    background-image: url("../images/test17.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  52.5% {
    background-image: url("../images/test13.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  72.5% {
    background-image: url("../images/test10.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  92.5% {
    background-image: url("../images/test23.jpeg");
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip7 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  13% {
    background-image: url("../images/test15.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  33% {
    background-image: url("../images/test17.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  53% {
    background-image: url("../images/test13.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  73% {
    background-image: url("../images/test10.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  93% {
    background-image: url("../images/test23.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip7 {
  0% {
    background-image: url("../images/test23.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  13% {
    background-image: url("../images/test15.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url("../images/test15.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  33% {
    background-image: url("../images/test17.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url("../images/test17.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  53% {
    background-image: url("../images/test13.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url("../images/test13.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  73% {
    background-image: url("../images/test10.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url("../images/test10.jpeg");
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  93% {
    background-image: url("../images/test23.jpeg");
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
}
.twitterLink {
  position: fixed;
  bottom: 0.5em;
  right: 0.5em;
}
.twitterLink img {
  width: 2em;
  filter: grayscale(100%);
  transition: filter 0.25s;
}
.twitterLink img:hover {
  filter: grayscale(0%);
}