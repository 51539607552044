
#root {
    min-height: 100vh;
}
body > #root > div {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
   }
   body{
     height: 100%;
     width: 100%;
   }

   /* @import url(https://unpkg.com/bootstrap@4.1.0/dist/css/bootstrap.min.css) */