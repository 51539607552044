.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: red;
  z-index: 21;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: red;
  z-index: 21;
}
.carousel-image-style{
    position:absolute;
      top:0;
      bottom:0;
      right: 0;
      left: 0;
      margin: auto; 
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
}



/*.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}*/

.img:hover {
    -webkit-transform:scale(1.2); 
            transform:scale(1.2);
  }
  .img {
    -webkit-transition: all 0.7s ease; 
            transition: all 0.7s ease;
  }

  /* Position the "next1 button" to the right */
.next1 {
    right: 0;
    border-radius: 3px 0 0 3px;
    transform: rotateZ(360deg);
  }
  /*carousel css start */
/* Next & previous buttons */
.prev1,
.next1 {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
  transform: rotateZ(360deg);
}

/* Position the "next1 button" to the right */
.next1 {
  right: 0;
  border-radius: 3px 0 0 3px;
  transform: rotateZ(360deg);
}

/* On hover, add a black background color with a little bit see-through */
.prev1:hover,
.next1:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: rotateZ(360deg);
}
/*carousel css end */



/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {
    
}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
    .bkimage {
        background-position: center center;
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }
  /*  .carousel-item img{
        transform: rotateZ(360deg);
        object-fit: cover;
        -moz-animation: spinHorizontalHomesceen 5s 2 linear;
        -o-animation: spinHorizontalHomesceen 5s 2 linear;    
        -webkit-animation: spinHorizontalHomesceen 5s 2 linear;
        animation: spinHorizontalHomesceen 5s 2 linear;
    }*/
}

/* For desktop devices */
@media (min-width: 1025px) {
  
   /* .carousel-item img{      
      width:100vw;
      margin: 0;      
      transform: rotateZ(360deg);
      object-fit: cover;
      -moz-animation: spinHorizontalHomesceen 5s 2 linear;
      -o-animation: spinHorizontalHomesceen 5s 2 linear;    
      -webkit-animation: spinHorizontalHomesceen 5s 2 linear;
      animation: spinHorizontalHomesceen 5s 2 linear;
   
      max-width: 100%;
    }*/

    
}
.carousel-inner > .item > img {
  margin: 0 auto;
}
.carousel-inner{
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;

  
  overflow: hidden;
  }
.carousel-item{

  width: 100vw;
  

  
  /*-moz-animation: spinHorizontalHomesceen 5s 2 linear;
  -o-animation: spinHorizontalHomesceen 5s 2 linear;    
  -webkit-animation: spinHorizontalHomesceen 5s 2 linear;
  animation: spinHorizontalHomesceen 5s 2 linear;
 
  -webkit-transform-origin:50% 50%;
  -moz-transform-origin:50% 50%;
  -ms-transform-origin:50% 50%;
  -o-transform-origin:50% 50%;
  transform-origin:50% 50%;
  -webkit-animation:scaleLoginImage 35s linear 0s infinite;
  animation:scaleLoginImage 35s linear 0s infinite;
  transition:all 1s ease-in-out;
  -webkit-transition:all 1s ease-in-out;
  -moz-transition:all 1s ease-in-out;
  -ms-transition:all 1s ease-in-out;
  -o-transition:all 1s ease-in-out;
  transition:all 1s ease-in-out;
 */
}
.carousel-item img{

  margin-left: 5rem;
 
  z-index: 99;
  height: 100%;
    /* max-height: 600px; */
    width: 1000px;
    height:100vh;
    width: 100vw;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  
    background-color: hsla(200, 40%, 30%, 0.4);      
    background-repeat: repeat-x;
    background-position: 0 20%, 0 100%, 0 50%, 0 100%, 0 0;
    background-size: 2500px, 800px, 500px 200px, 1000px, 400px 260px;
  
}


@keyframes spinHorizontalHomesceen {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
    
}
@-ms-keyframes kenburns
{
  0%
  {
    -o-transform:scale(1);
    -o-transition:-o-transform 35000ms linear 0s
  }
  100%
  {
    -o-transform:scale(1.3);
    -o-transition:-o-transform 35000ms linear 0s
  }
}
@-o-keyframes kenburns
{
  0%
  {
    -o-transform:scale(1);
    -o-transition:-o-transform 35000ms linear 0s
  }
  100%
  {
    -o-transform:scale(1.3);
    -o-transition:-o-transform 35000ms linear 0s
  }

}
@keyframes kenburns
{
  0%
  {
    transform:scale(1);
    transition:transform 35000ms linear 0s
  }
  100%
  {
    transform:scale(1.3);
    transition:transform 35000ms linear 0s
  }

}

.welcometext{
  text-shadow: 2px 2px 5px red;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
}
.welcomeusertxt {
  text-align: right;
  font-size: 20px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
  font-weight:bold;
}

.logincontainer {
  /*display: grid;*/
  width: 100vw;
  height: 100vh;
 /* grid-template-columns: 1fr;*/
  -webkit-transform: perspective(1000px) rotateZ(360deg);
  transform: perspective(1000px) rotateZ(360deg);
}

.loginimageclass2{
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotateZ(360deg);
  object-fit: contain;
}

.imageclass{
  background-size: auto 100%;
  position: absolute;
  height: 10vh;
  width: 10vw;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}