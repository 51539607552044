
.pane-center{
  z-index:inherit !important;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.cursor {
  cursor: pointer;
}



/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {
      .modal {
        text-align: center;
        position: absolute;
          top: 15%;
          left: 50%;
          width: 100%;
        transform: translate(-50%, -50%);
        transform:  perspective(1000px) rotateY(45deg);
        z-index: 10000000 !important;;
      }
      .modal.fade {
        transform: translate(-50%, -50%);
        transform:  perspective(1000px) rotateY(45deg);
        z-index: 10000000 !important;
        left: 50%;
        width: 100%;
        overflow: hidden;
        background: transparent;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      }
      
    .modal-dialog {
      width: 0px;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 20px;
      right: 0;
      height: 100vh;
      display: flex;
      max-width: 100%;
      max-height: 100%;
    }
}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
      .modal {
        text-align: center;
        position: absolute;
          top: 10%;
          left: 50%;
          width: 100%;
        transform: translate(-50%, -50%);
        transform:  perspective(1000px) rotateY(45deg);
        z-index: 10000000 !important;;
      }
      .modal.fade {
        transform: translate(-50%, -50%);
        transform:  perspective(1000px) rotateY(45deg);
        z-index: 10000000 !important;
        left: 50%;
        width: 100%;
        overflow: hidden;
        background: transparent;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      }
      
    .modal-dialog {
      width: 0px;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 20px;
      right: 0;
      height: 100vh;
      display: flex;
      max-width: 100%;
      max-height: 100%;
    }
    .cursor {
      cursor: default;
    }
}
/* For desktop devices */
@media (min-width: 1025px) {
      modal {
        text-align: center;
        position: absolute;
          top: 10%;
          left: 50%;
          width: 30vw;
        transform: translate(-10%, -50%);
        transform:  perspective(1000px) rotatez(-10deg);
        z-index: 10000000 !important;;
      }
      .modal.fade {
        transform: translate(-10%, -50%);
        transform:  perspective(1000px) rotatez(-10deg);
        z-index: 10000000 !important;
        left: 50%;
        width: 30vw;
        overflow: hidden;
        background: transparent;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
      }
      
    .modal-dialog {
      width: 0px;
      margin: 0;
      top: 0;
      bottom: 0;
      left: 20px;
      right: 0;
      height: 100vh;
      display: flex;
      max-width: 100%;
      max-height: 100%;
      }
    }