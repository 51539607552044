html, body {
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100vh;
  width:100vw;
}
html, body, #app, #app>div {
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100vh;
  width:100vw;
}
html, body, #root, .App {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* css changes for  for login and singup start  bootstrap */  

 *{
  box-sizing: border-box;
}


h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}

body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex-end;
  justify-content: flex-end;
  flex-direction: center;
  text-align: left;
  
}
.example-card {
  max-width: 300px;
  margin-left: 800px;
  margin-top:0px;
} 
.auth-inner {
  
  width: 250px;
  top: 5%;
  overflow: hidden;
  margin: auto;
  position: absolute;
  background: #ffffff;
  box-shadow: 5px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 20px 55px 15px 55px;
  border-radius: 15px;
  transition: all .3s;
  z-index: 199;
  
  
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.login {

  /*background: url("../src/images/littlevisuals-high-quality-hd-banner-images-stock-photos-free-laptop-topview-download.jpg") no-repeat; 
  background: url("../src/images/test15.jpeg") no-repeat; 

  
  background-size: cover;
  min-width: 100%;
  min-height: 100%;*/
  background: url("../src/images/test15.jpeg") no-repeat ; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
html, body, .login {
  height: 100vh;
}

/* css changes for login and singup end */
/*login background image css */
.bkimage {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bkimage-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
}
/*for mobile */
@media (min-width: 50px) and (max-width: 480px) {
    .bkimage {
      background-position: relative center;
      width: 80vh;
      height: 100vh;
      
      
      
    }

}
/*For tablets */
@media (min-width: 481px) and (max-width: 1024px) {
    .bkimage {
        background-position: center center;
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }
    
}

/* For desktop devices */
@media (min-width: 1025px) {
    .bkimage {
      background-position: center center;
      transform: rotateY(360deg);
    }

    .bkimage-content {
        align-items: flex-end;
        justify-content: center;
    }

  
    
}

/*login background image css */
/*3d text start */
.textblue{
              
              
  transform: translate(0%,-0%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 2em;
  font-weight: 700;
  color: red;
  
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}
.textSignIn{
  
  
  transform: translate(0%,-0%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 2em;
  font-weight: 700;
  color :#ec4949;
  white-space: nowrap;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}

.textLoggedinAs{

  transform: translate(10%,-10%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 2em;
  font-weight: 700;
  color :#500707;
  z-index: 99;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
  
}

.textcopyright{

  transform: translate(0%,-0%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 1em;
  font-weight: 700;
  color :#500707;
  z-index: 99;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
 
}

.textbrand{

  transform: translate(10%,-10%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 1.5em;
  font-weight: 700;
  color :#a7f152;
  
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
  
}



/*3d text end */
.userdtlsnavbar{
  color :#fcf5f5;
}

.cursor {
  cursor: pointer;
}

#offset {
  animation: letter-flicker 2s linear infinite;
}
@keyframes letter-flicker {
  0% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  2% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  4% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  
  19% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  21% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  23% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  
  80% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  83% {
    opacity:0.4;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  87% {
    opacity:1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
}

#offsetbrand {
  animation: letter-flicker 2s ease-in-out forwards infinite;
}
@keyframes letter-flicker {
  0% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  2% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  4% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  
  
  19% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  21% {
    opacity:0.1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  23% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  
  80% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  83% {
    opacity:0.4;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
  87% {
    opacity:1;
    text-shadow: 0px 0px 29px rgb(203, 243, 179);
  }
}
/*silk slider */
.silkcontainer {
  padding: 40px;
  background: #419be0;
}

.slick-slide img {
  margin: auto;
}

.loginsilk-img{
  position:absolute;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
    margin: auto; 
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
}
