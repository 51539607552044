@import url("https://fonts.googleapis.com/css?family=Permanent+Marker");
@font-face {
  font-family: "AmericanCaptain";
  src: url("https://res.cloudinary.com/wyk/raw/upload/v1537995321/ballack/AmericanCaptain.woff2") format("woff2"), url("https://res.cloudinary.com/wyk/raw/upload/v1537995321/ballack/AmericanCaptain.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


.postercontainer {
  margin: auto;
  padding: 50px 10px;
  margin-right: auto;
  -moz-animation: spinning-poster  10s 15s  ease-in-out infinite;
  -o-animation: spinning-poster  10s 15s  ease-in-out infinite;    
  -webkit-animation: spinning-poster  10s 15s  ease-in-out infinite;
  animation: spinning-poster  10s 15s  ease-in-out infinite; 
  /* transform: scale(1) rotateY(5deg);
  -webkit-transform-origin: 5% 5%;
  transform-origin: 45% 5%; */

  -webkit-transform-origin:  75% 45%;
  transform-origin: 75% 45%;
  -webkit-transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
  transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);

}

.hbd {
  text-align: center;
  color: #fff;
  font-family: "Permanent Marker", cursive;
  font-size: 24px;
}
@media (min-width: 768px) {
  .hbd {
    font-size: 3vw;
  }
}
.hbd:first-child span {
  background: linear-gradient(90deg, #e3ffe7 0%, #efd5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  -webkit-animation: shine01 4s linear infinite;
          animation: shine01 4s linear infinite;
}
.hbd:nth-of-type(2) span {
  background: linear-gradient(90deg, #e3ffe7 0%, #efd5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  -webkit-animation: shine02 4s linear infinite;
          animation: shine02 4s linear infinite;
}

.hero {
  position: relative;
  background-size: contain;
  overflow: hidden;
  width: 80%;
  box-shadow: 0px 0px 100px 0px #c80bf780;
  margin: 50px auto;

}
@media (min-width: 768px) {
  .hero {
    width: 60%;
  }
}
@media (min-width: 1000px) {
  .hero {
    width: 40%;
  }
}

.ballack {
  display: block;
  width: 100%;
}

.quote {
  display: grid;
  mix-blend-mode: multiply;
  text-transform: uppercase;
  font-family: "AmericanCaptain", sans-serif;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 0;
  width: 50%;
  color: #fff;
  font-size: 11vw;
  text-align: right;
  transform: translate(0, -50%) scale(1, 1.3);
  line-height: 0.6;
}
@media (min-width: 768px) {
  .quote {
    font-size: 8.2vw;
  }
}
@media (min-width: 1000px) {
  .quote {
    font-size: 5.2vw;
  }
}
@media (min-width: 1200px) {
  .quote {
    font-size: 4.3vw;
  }
}
.quote::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 200vh;
  background: #ee082ef5;
  z-index: -1;
  transform: translate(0, -60%);
  opacity: 2;
}

@-webkit-keyframes shine01 {
  to {
    background-position: -200% center;
  }
}

@keyframes shine01 {
  to {
    background-position: -200% center;
  }
}
@-webkit-keyframes shine02 {
  to {
    background-position: 200% center;
  }
}
@keyframes shine02 {
  to {
    background-position: 200% center;
  }
}


@keyframes spinning-poster {
  0% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    z-index: 99;
  }
  20% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(-55deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(-55deg) rotateX(0deg);
    z-index: 99;
  }
  /* 30% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  } */
  30% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);
  }
  100% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-60deg) rotateX(0deg);
  }
}

@-webkit-keyframes spinning-poster {
  0% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    z-index: 99;
  }
  20% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(-35deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(-35deg) rotateX(0deg);
    z-index: 99;
  }
  /* 30% {

    -webkit-transform-origin: 45% 45%;
    transform-origin: 45% 45%;
    -webkit-transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    transform:  perspective(1000px) rotateZ(0deg) rotateY(25deg) rotateX(0deg);
    z-index: 99;
  } */
  30% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
  }
  100% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 75% 45%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
  }
}



@keyframes spinning-poster2 {
  
  30% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 80% 15%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  }
  100% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 80% 15%;;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  }
}

@-webkit-keyframes spinning-poster2 {
  30% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 80% 15%;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-40deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  }
  100% {

    -webkit-transform-origin: 75% 45%;
    transform-origin: 80% 15%;;
    -webkit-transform: scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
    transform:  scale(1) perspective(1000px) rotateZ(0deg) rotateY(-10deg) rotateX(0deg);
  }
}